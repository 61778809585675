import { restartLoginProcess } from "./auth";

export const NETWORK_ERROR = "NETWORK_ERROR";
export const NETWORK_OK = "NETWORK_OK";

const initialState = {
  error: false,
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NETWORK_ERROR:
      return {
        ...state,
        error: true,
        error_message: action.message,
        error_object: action.error,
      };
    case NETWORK_OK:
      return {
        ...state,
        error: false,
        error_message: "",
        error_object: undefined,
      };
    default:
      return {
        ...state,
        error: false,
      };
  }
};

export const throwErr = (dispatch, message = "", err, status) => {
  dispatch({
    type: NETWORK_ERROR,
    message: message,
    error: err,
  });
  if (status === 401) {
    console.log(err);
    dispatch(restartLoginProcess());
  }
};
