import { SHOPS } from "../config/shops";
import { withRootApi } from "./api";
import { throwErr } from "./error";
import { getLatestProducts } from "./trending-products";
import { get } from "lodash";

export const LOAD_SHOP = "store/LOAD";
export const DELETE_SHOP = "store/CLEAR";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SHOP:
      return {
        ...state,
        ...action.shopDetails,
      };
    case DELETE_SHOP:
      return {};
    default:
      return state;
  }
};

export const LOAD_CHAIN = "chain/LOAD";
export const DELETE_CHAIN = "chain/CLEAR";

export const chainReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHAIN:
      return {
        ...action.chainDetails,
      };
    case DELETE_CHAIN:
      return {};
    default:
      return state;
  }
};

// Action dispatcher
export const updateActiveStore = (shop) => {
  return (dispatch, getState) => {
    console.log(getState());
    const shopObj = shop.name ? shop : get(getState(), ["chain", "shops"]).find((s) => s.id === shop.id);
    // this is a hack here. delete the current store and update it with the updated store.
    dispatch({
      type: DELETE_SHOP,
    });
    dispatch({
      type: LOAD_SHOP,
      shopDetails: shopObj,
    });
    dispatch(getLatestProducts());
  };
};

export const loadChainDetails = () => {
  const currentShopObject = getShop();
  const chainId = currentShopObject.chain_id;
  return (dispatch, getState) => {
    if (chainId) {
      const apiRoot = withRootApi(dispatch, getState, throwErr);
      return apiRoot
        .url("/chain_details.json")
        .query({ chain_id: chainId })
        .get()
        .json((response) => {
          if (response && response.data) {
            dispatch({
              type: LOAD_CHAIN,
              chainDetails: response.data,
            });
            const activeShopID = get(getState(), ["shop", "id"]);
            // Update shop also. There's a chance that shop updated their delivery charge and all, it should reflect.
            if (activeShopID) {
              const chain = response.data;
              const shopObj = get(chain, "shops", []).find((shop) => shop.id === activeShopID);
              dispatch({
                type: LOAD_SHOP,
                shopDetails: shopObj || get(chain, ["shops", 0], {}),
              });
            }
            else {
              const chain = response.data;
              dispatch({
                type: LOAD_SHOP,
                shopDetails: get(chain, ["shops", 0], {}),
              });
            }
          }
        });
    }
  };
};
const getShop = () => {
  const shopSlug =
    window.location.host.split(".")[0] === "www" || window.location.host.split(".")[0] === "shop"
      ? window.location.host.split(".")[1]
      : window.location.host.split(".")[0];
  const fallbackShop = {
    // shop_id: 1,
    // chain_id: 1,
    // shop_slug: "localhost",
    // colors: {
    //   primary: "#ab3838",
    // },
    // name: "The Whicart",
    // logo:
    //   "https://lh3.googleusercontent.com/RDE3_9C5VRjwsgp_s-WCkkWMa86ep8ROoHBHWrbASw_9HZ_-tQtnOXjps8CIl8zE1w=s360-rw",
    config: {
      pageTitle: "Showcart | Order online food, groceries and other in Perambra, Quilandi, Atholi | free delivery",
      pageDescription: "Showcart | Online food, groceries, meat, fish delivery",
      hideBrandNameTitle: true,
      maximumDeliveryDistance: 10,
      metaDirectory: "/showcart"
    },
    shop_id: 2,
    chain_id: 2,
    shop_slug: "showcart",
    colors: {
      primary: "#0cadf4"
    },
    name: "Showcart",
    logo: "https://i.ibb.co/KKksNg5/SW.png",
    app: {
      link: "http://onelink.to/k6e4ua",
    },
  };
  // development purposes
  if (shopSlug.includes("localhost")) {
    return SHOPS.filter((shop) => shop.shop_slug === "localhost")[0];
  }
  return SHOPS.filter((shop) => shop.shop_slug === shopSlug)[0] || fallbackShop;
};

export const isOnWhicartDomain = () => window.location.host.indexOf("whicart") > -1;

export { getShop };
