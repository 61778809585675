/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import rootReducer from "./modules";
import { save, load } from "redux-localstorage-simple";
import LogRocket from 'logrocket';

export const history = createHistory();

const enhancers = [];
const statesToSave = ["cart", "wishlist", "deliveryStaff", "auth", "address", "shop", "cities"];
const middleware = [
  save({
    states: statesToSave,
    namespace: "whicart_storage",
    debounce: 500
  }),
  thunk,
  routerMiddleware(history)
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware, LogRocket.reduxMiddleware()),
  ...enhancers
);

const store = createStore(
  connectRouter(history)(rootReducer),
  load({
    states: statesToSave,
    namespace: "whicart_storage",
    debounce: 500
  }),
  composedEnhancers
);

export default store;
