import React from "react";
import "./styles.css";

const BoxDetails = ({ children, active, ...props }) => {
  return (
    <div className={`box-details ${active ? "box-details--active" : ""}`} {...props}>
      {children}
    </div>
  );
};

export default BoxDetails;
