import { useState, useEffect } from "react";

export const useMyLocation = () => {
  const [position, setPosition] = useState();

  // locationStatus status
  //  0: initialising
  //  1: being fetched
  //  2: fetched successfully
  // -1: error fetching
  // -2: error no support
  const [locationStatus, setLocationStatus] = useState(0);

  function error() {
    setLocationStatus(-1);
  }



  useEffect(() => {
    const options = {
      enableHighAccuracy: true
    }
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setPosition({
        lat: latitude,
        lng: longitude
      });
      setTimeout(() => {
        setLocationStatus(2);
      });
    }
    if (!navigator.geolocation) {
      setLocationStatus(-2);

    } else {
      setLocationStatus(1);
      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }, []);

  return [position, locationStatus]
}
