import React from "react";
import { connect } from "react-redux";
import "./style.css";
import { Link } from "react-router-dom";

const ProfileDetails = ({ profile = {}, hideProfileDetails }) => {
  return (
    <div className="profile-details">
      <h3 className="profile-details__heading">Profile details</h3>
      <div>
        <p>
          Name: {profile.first_name} {profile.last_name}
        </p>
        <p>Email: {profile.email},</p>
        <p>Phone: {profile.phone}</p>
      </div>
      {!hideProfileDetails ? <Link to="/checkout">Change profile details</Link> : null}
    </div>
  );
};

const mapStateToProps = ({ auth = {} }) => {
  return {
    profile: auth.user
  };
};

export default connect(
  mapStateToProps,
  null
)(ProfileDetails);
