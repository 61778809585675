import { withRootApi } from "./api";
import { throwErr } from "./error";
import get from "lodash/get";
export const GET_SEARCH_REQUESTED = "Search/GET_REQUESTED";
export const GET_SEARCH = "Search/GET";

const initialState = {
  isSearchRequesting: true,
  searchResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SEARCH_REQUESTED:
      return {
        ...state,
        isSearchRequesting: true,
      };

    case GET_SEARCH:
      return {
        ...state,
        searchResults: action.Search,
        isSearchRequesting: false,
      };
    default:
      return state;
  }
};

export const getSearchResults = (query) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SEARCH_REQUESTED,
    });

    const apiRoot = withRootApi(dispatch, getState, throwErr);

    return apiRoot
      .url(`/items/search.json`)
      .query({ query: query })
      .get()
      .badRequest((err) => throwErr(dispatch, "Bad request. Please try again later!", err))
      .unauthorized((err) => throwErr(dispatch, "Unauthorization issue. Please try re-logging in.", err))
      .forbidden((err) => throwErr(dispatch, "You don' have permission to do this operation.", err))
      .notFound((err) => throwErr(dispatch, "The resource you've been searching for not found.", err))
      .timeout((err) => throwErr(dispatch, "Timeout! Please try again!", err))
      .internalError((err) => throwErr(dispatch, "Oops! Something went wrong! Please contact support.", err))
      .fetchError((err) =>
        throwErr(
          dispatch,
          "Some network error has been occured. Verify you've proper credentials and network access",
          err
        )
      )
      .json((response) =>
        dispatch({
          type: GET_SEARCH,
          Search: get(response, ["data"]),
        })
      );
  };
};
