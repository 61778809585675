import React from "react";
import styles from "./styles.module.css";
import Button from "../button";

const Input = ({
  id,
  label = "Enter value here",
  handleInputChange,
  type = "text",
  value,
  icon = "",
  className = "",
  ...props
}) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <label htmlFor={id}>{label}</label>
      <span className={styles.inputGroup}>
        <input
          type={type}
          name={id}
          id={id}
          placeholder={label}
          onChange={handleInputChange}
          value={value}
          {...props}
        />
        {icon.length > 1 ? (
          <Button type="submit">
            <span className={icon} />
          </Button>
        ) : null}
      </span>
    </div>
  );
};

export default Input;
