import React, { useEffect , useState } from 'react';
import { useSelector , useDispatch } from 'react-redux';
import { Drawer, Typography, Alert } from "antd";
import { getThemeValue } from '../../utils/helpers';

import { setActiveCity, setCityPicker, setStorePicker } from '../../modules/cities';

const { Paragraph } = Typography;

export const NotServingLocation = () => {
  const isUserOutOfCity = useSelector(state => {
    return state.permissions.isUserOutOfCity;
  });
  const dispatch = useDispatch();

  const shopName = getThemeValue([ "name"]);

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setVisible(isUserOutOfCity);
    if(isUserOutOfCity) {
      dispatch(setCityPicker(true));
      dispatch(setStorePicker(true));
      dispatch(setActiveCity(null));
    }
  }, [isUserOutOfCity, dispatch])
  return <Drawer title={`${shopName} is not serving in your location`} placement={"bottom"} visible={visible} onClose={onClose} zIndex={1011}>
    <Paragraph>
      Sorry, We're not delivering at your location the moment.
      <Alert message={<span>We'll let you know when we start delivering at your location.</span>} type="error" showIcon />
      <small>{isUserOutOfCity ? 'Out of city' : 'Deliverable location'}</small>
    </Paragraph>
  </Drawer>
}
