import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addToCart, removeFromCart, reduceFromCart, clearWholeCart } from "../../modules/cart";
import Link from "../../molecules/link";
import ProductsList from "../../molecules/products-list";
import "./style.css";
import AmountDetails from "../../molecules/amount-details";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import LoginModal from "../login-modal";

class Cart extends React.Component {
  constructor() {
    super();
    this.state = {
      showLoginModal: false,
    };
    this.checkoutAction = this.checkoutAction.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
  }
  checkoutAction() {
    if (isEmpty(get(this.props.auth, ["token"]))) {
      this.setState({
        showLoginModal: true,
      });
    } else {
      this.props.history.push("/addresses");
    }
  }
  closeLoginModal() {
    this.setState({
      showLoginModal: false,
    });
  }
  render() {
    const isUserLoggedIn = this.props.auth.token && this.props.auth.token["access-token"];
    const noAddressForUser = get(this.props.address, ["data"], []).length === 0;
    return (
      <div className="cart-page">
        {this.state.showLoginModal ? <LoginModal onClose={this.closeLoginModal} /> : null}
        <div className="cart__title">
          <h1>Cart</h1>
          <p className="cart__items-info">{this.props.cart.items.length} items</p>
        </div>
        {this.props.cart.items.length ? (
          <div className="cart">
            <ProductsList
              products={this.props.cart.items}
              reduceFrom={this.props.reduceFromCart}
              addTo={this.props.addToCart}
              removeFrom={this.props.removeFromCart}
              {...this.props}
            />
          </div>
        ) : null}
        {noAddressForUser ? (
          <Link to={`/addresses?redirectTo=/cart&buttonLabel=Add address`}>Add an address to see price details</Link>
        ) : (
          <div>
            {isUserLoggedIn ? (
              this.props.cart.items.length ? (
                <div className="amount-details">
                  <AmountDetails cart={this.props.cart} />
                </div>
              ) : null
            ) : null}
            <div className="cart__buttons">
              <div className="cart__buttons__items">
                {this.props.cart.items.length ? (
                  [
                    <div onClick={this.checkoutAction} className="link link--success" key="btn">
                      Checkout
                    </div>,
                    <p key="or">Or</p>,
                  ]
                ) : (
                  <p>No items in your cart. </p>
                )}
                <Link to="/" type="normal">
                  Continue shopping
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ cart, auth, address }) => ({
  cart: cart,
  auth: auth,
  address: address,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addToCart,
      reduceFromCart,
      removeFromCart,
      clearWholeCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
