import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./styles.css";
import { placeOrder } from "../../modules/order";
import ProductsList from "../../molecules/products-list";
import AddressDetails from "../../molecules/address-details";
import AmountDetails from "../../molecules/amount-details";
import DeliveryStaff from "../../molecules/deliverystaff-details";
import get from "lodash/get";
import { getThemeValue } from "../../utils/helpers";
import LoadingIndicator from "../../molecules/loading-indicator";
import { AntdBtn } from "../../molecules/AntdButton";

class PlaceOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      orderSubmitted: false,
    };
    this.placeOrder = this.placeOrder.bind(this);
  }

  placeOrder(e) {
    e.preventDefault();
    this.setState({
      orderSubmitted: true,
    });
    this.props.placeOrder();
  }

  render() {
    return (
      <div id="main-registration-container" className="place-order-page">
        <h1>Order Details</h1>
        <div>
          <form name="userRegistrationForm" onSubmit={this.placeOrder}>
            {this.state.orderSubmitted ? <LoadingIndicator /> : null}
            <AntdBtn
              block
              type="primary"
              htmlType="submit"
              loading={this.state.orderSubmitted}
              disabled={!this.props.cart.items.length}
            >
              Place order
            </AntdBtn>

            <p> In the cart: </p>
            <ProductsList products={this.props.cart.items} {...this.props} />
            <h3 className="address-details__heading">Ship to</h3>
            <AddressDetails
              address={this.props.address.data[this.props.address.activeIndex]}
              showChangeShippingAddress
            />
            {get(this.props.deliveryStaff, ["data"], []).length &&
            getThemeValue(["config", "enableDeliveryPicker"]) ? (
              <>
                <h3 className="address-details__heading">Delivery partner</h3>
                <DeliveryStaff
                  staff={
                    this.props.deliveryStaff.data[
                      this.props.deliveryStaff.activeIndex
                    ]
                  }
                  enableChangeDeliveryStaff
                />
              </>
            ) : null}
            <h3>Payment details</h3>
            <AmountDetails cart={this.props.cart} />
            <p>
              <strong>Payment Method:</strong> Cash on Delivery
            </p>
            {this.props.order.error ? (
              <p className="error">
                Something went wrong. Please try again. Error:{" "}
                {this.props.order.error_message}
              </p>
            ) : null}
            <AntdBtn
              block
              type="primary"
              htmlType="submit"
              loading={this.state.orderSubmitted}
              disabled={!this.props.cart.items.length}
            >
              Place order
            </AntdBtn>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ address, cart, deliveryStaff, order }) => {
  return {
    address,
    deliveryStaff,
    cart,
    order,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      placeOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);
