import React from "react";

import "./style.css";
import img from "./wall.jpg";
import IconLink from "../iconLink";
import { getThemeValue } from "../../utils/helpers";

const ComingSoon = () => {
  return (
    <div className="ComingSoon">
      <IconLink
        icon={getThemeValue(["logo"])}
        to="/"
        linkTo="/"
        alt="logo"
        clazzName="header__logo"
        showContentAllTime
        hideChildren={getThemeValue(["config", "hideBrandNameTitle"], false)}
      >
        {getThemeValue(["name"], "Whicart")}
      </IconLink>
      <img src={img} alt="Something interesting has been cooking here" />
      <h2 className="ComingSoon__title">Something interesting has been cooking here</h2>
      <p>Be ready for this next big thing!</p>
      <p className="muted muted--small">
        Illustration from <a href="https://www.freepik.com/free-photos-vectors/food">freepik</a>
      </p>
    </div>
  );
};
export default ComingSoon;
