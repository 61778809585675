import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { withHeaderedRootApi } from "../../modules/api";
import { useAuthentication, useShopId, useQuery } from "../../hooks/useAuthentication";
import { Orders } from "./order-list";
import LoadingIndicator from "../../molecules/loading-indicator";
import { useDispatch } from "react-redux";
import { Result, Button, Typography } from 'antd';

const { Text } = Typography;


export const OrderSuccess = () => {
  // const { orderId } = useParams();
  const orderId = useQuery("orderId");
  return (
    <div>
      {
        orderId !== 'undefined' ?
        <>
          <Result
            status="success"
            title="Order Successfull!"
            subTitle={<>
              <Text>You're order has been processed successfully. We'll get back to you soon.</Text>
              </>}
            extra={[
              <Button type="primary" key="console">
                <Link to="/">Home page</Link>
              </Button>,
              <Button key="buy">
                <Link to="/orders"> View my orders</Link>
              </Button>,
            ]}
          />
        </> : null
      }
    </div>
  );
};

export const OrdersPage = () => {
  // const tabs = [
  //   {
  //     label: 'Active Orders',
  //     key: 'active',
  //     content: ActiveOrders
  //   },
  //   {
  //     label: 'Past Orders',
  //     key: 'past',
  //     content: PastOrders
  //   }
  // ];
  const tabs = [
    {
      label: "Orders",
      key: "orders",
      content: Orders
    }
  ];
  const [activeTab, setActive] = useState(tabs[0]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const authentication = useAuthentication();
  const shopID = useShopId();
  const dispatch = useDispatch();
  useEffect(
    () => {
      const getOrders = async () => {
        const rootApiWithHeaders = withHeaderedRootApi(authentication, shopID, dispatch);
        const orders = await rootApiWithHeaders
          .url(`/orders.json`)
          .get()
          .json(res => res.data);
        orders && setOrders(orders);
        setLoading(false);
      };
      getOrders();
    },
    [authentication, dispatch, shopID]
  );
  return (
    <div className="padding">
      <div className="profile-details">
        <h3 className="profile-details__heading">My Orders</h3>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ul className="tab">
            {tabs.map(tab => (
              <li key={tab.key} onClick={() => setActive(tab)}>
                {tab.label}
              </li>
            ))}
          </ul>
        )}
        {React.createElement(activeTab.content, {
          orders: orders
        })}
      </div>
    </div>
  );
};

// const ActiveOrders = ({ orders }) => {
//   return 'Active orders';
// };
// const PastOrders = ({ orders }) => {
//   return 'Past orders';
// };
