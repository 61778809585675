import React from "react";
import "./style.css";
import icon from "./loading.svg";
const LoadingIndicator = () => (
  <div className="loading-indicator full-width">
    <div className="loading-indicator__icon">
      <img src={icon} alt="loading" />
    </div>
  </div>
);

export const Loader = () => {
  return (
    <div className="loading-indicator__icon loader__small">
      <img src={icon} alt="loading" />
    </div>
  );
};
export default LoadingIndicator;
