import React from "react";
import styles from "./BorderContent.module.css";
export const BorderContent = ({ color = "", className = "", children }) => {
  const inlineStyles = {
    background: color,
  };
  return (
    <div className={`${className} ${styles.component}`} style={inlineStyles}>
      {children}
    </div>
  );
};
