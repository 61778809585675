import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";
import { BorderContent } from "../../atoms/border-content";
import IconLink from "../../atoms/iconLink";
import CartIcon from "../cart-icon";
import { LoginLink } from "../login-link";
import styles from "./Navbar.module.css";

export const Navbar = () => {
  const auth = useSelector((state) => get(state, ["auth"], {}));
  return (
    <BorderContent className={styles.component}>
      <IconLink to="/" alt="cart" fontIcon="icon-items" clazzName={styles.iconLink} />
      <CartIcon clazzName={`${styles.iconLink} ${styles.cartLink}`} />
      <IconLink to="/search" alt="search" fontIcon="icon-search" clazzName={styles.iconLink} />
      {auth.isLoggedIn ? (
        <IconLink linkTo="/profile" fontIcon="icon-user" clazzName={styles.iconLink} />
      ) : (
        <LoginLink clazzName={styles.iconLink} />
      )}
    </BorderContent>
  );
};
