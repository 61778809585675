import { withRootApi, fetchApi } from "./api";
import { throwErr } from "./error";
import get from "lodash/get";
export const GET_CATEGORY_REQUESTED = "category/GET_REQUESTED";
export const GET_CATEGORY = "category/GET";
export const GET_ALL_CATEGORY = "category/GET_ALL";

const initialState = {
  isCategoryRequesting: true,
  category: [],
  allCategories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_REQUESTED:
      return {
        ...state,
        isCategoryRequesting: true
      };

    case GET_CATEGORY:
      return {
        ...state,
        category: action.category,
        isCategoryRequesting: false
      };
    case GET_ALL_CATEGORY:
      return {
        ...state,
        allCategories: action.categories
      };
    default:
      return state;
  }
};

export const getCategory = categoryId => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CATEGORY_REQUESTED
    });

    const apiRoot = withRootApi(dispatch, getState, throwErr);

    return apiRoot
      .url(`/categories/${categoryId}`)
      .get()
      .badRequest(err => throwErr(dispatch, "Bad request. Please try again later!", err))
      .unauthorized(err => throwErr(dispatch, "Unauthorization issue. Please try re-logging in.", err))
      .forbidden(err => throwErr(dispatch, "You don' have permission to do this operation.", err))
      .notFound(err => throwErr(dispatch, "The resource you've been searching for not found.", err))
      .timeout(err => throwErr(dispatch, "Timeout! Please try again!", err))
      .internalError(err => throwErr(dispatch, "Oops! Something went wrong! Please contact support.", err))
      .fetchError(err =>
        throwErr(
          dispatch,
          "Some network error has been occured. Verify you've proper credentials and network access",
          err
        )
      )
      .json(response =>
        dispatch({
          type: GET_CATEGORY,
          category: get(response, ["data"])
        })
      );
  };
};

export const getCategoryPure = (categoryId, shopId) => {
  const apiRoot = fetchApi(shopId);
  return apiRoot
    .url(`/categories/${categoryId}`)
    .get()
    .json(response => get(response, ["data"]));
};

export const getCategoriesWith10Items = () => {
  return (dispatch, getState) => {
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url("/categories.json")
      .query({ page: 1, per_page: 1000 })
      .get()
      .json(response => {
        dispatch({
          type: GET_ALL_CATEGORY,
          categories: get(response, ["data"])
        });
      });
  };
};
