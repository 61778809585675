import React from "react";
import { ChooseCity } from "../ChooseCity";
import "./style.css";
export const ChooseShopAndAddress = () => {
  return (
    <div className="ChooseShopAndAddress">
      <ChooseCity />
      {/* <ChooseShopFromChain /> */}
      {/* <ChooseAddress /> */}
    </div>
  );
};
