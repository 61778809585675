import React from "react";
import { WCImage } from "../image";
import styles from "./ShopItem.module.css";
export const ShopItem = ({ shop }) => {
  // const deliveryTime = getThemeValue(["config", "deliveryTime"]);
  return (
    <div className={`${styles.shopItem}`}>
      <WCImage src={shop.image} aspectRatio="square" />
      <div className={styles.data}>
        <h3 className="t-h3">{shop.name}</h3>
        {shop.description ? <p className="t-text-muted">{shop.description}</p> : null}
        {shop.delivery_time ? <p className="t-text-muted">Delivers in {shop.delivery_time}</p> : null}
        <div>
          { shop.rating ?
            [...Array(shop.rating)].map(() => <span className={"icon-star"} style={{color: "#fadb14" }}/>) :
            null
          }
        </div>
        <p className="t-text-muted">{
          shop.is_open !== false ?
            <span className={styles.taking}>Taking orders</span> :
            <span className={styles.not_taking}>Not taking orders now</span>
          }</p>
        { shop.budget || shop.preparation_time ? <p className="t-text-muted">
          {shop.preparation_time}
          {shop.budget ? `${shop.preparation_time ? ' • ' : ''}${shop.budget}` : ``}
          </p>: null
        }
      </div>
    </div>
  );
};
