import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default class Thankyou extends React.Component {
  render() {
    return (
      <div>
        <h1>Thankyou</h1>
        <p>Thank you for shopping with Whicart</p>
        <p>
          Your order has been placed with ID #23450. An order confirmation email has been sent to 'customer@gmail.com'
        </p>
        <Link to="/">Continue shopping</Link>
        {/* <Link to="/orders">View your orders</Link> */}
      </div>
    );
  }
}
