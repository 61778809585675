import { withHeaderedRootApi, withRootApi } from "./api";
import get from "lodash/get";
import { push } from "connected-react-router";
import { pushWholeCartToServer } from "./cart";
import { throwErr } from "./error";
// Settign up authentication token
export const AUTH_SET_TOKEN = "auth/SET_TOKEN";
export const AUTH_DISCARD_TOKEN = "auth/DISCARD_TOKEN";
export const AUTH_SET_USER = "auth/SET_USER";

export const AUTH_RESTART_LOGIN_PROCESS = "auth/RESTART_LOGIN_PROCESS";
export const AUTH_SENDING_OTP = "auth/SENDING_OTP";
export const AUTH_SENT_OTP = "auth/SENT_OTP";
export const AUTH_SENT_OTP_FAILED = "auth/SENT_OTP_FAILED";
export const AUTH_VERIFYING_OTP = "auth/VERIFYING_OTP";
export const AUTH_VERIFIED_OTP = "auth/VERIFIED_OTP";
export const AUTH_FAILED_OTP = "auth/FAILED_OTP";

export const AUTH_PROFILE_UPDATE = "auth/PROFILE_UPDATE";
export const AUTH_ADDRESS_UPDATE = "auth/ADDRESS_UPDATE";
export const AUTH_PLACE_ORDER_UPDATE = "auth/PLACE_ORDER_UPDATE";
export const AUTH_CART_UPDATE = "auth/CART_UPDATE";

// Setting up basic user status or state:
// 3: Returning user - user is verified and logged in
// 2: user is not verified and waiting for otp verification
// 1: Fresh user - user is not provided mobile number.
const initialState = {
  user: {
    id: null,
    returning_user: null,
  },
  isLoggedIn: false,
  token: {},
  status: 1,
  loading: false,
  error: false,
  errorSent: false,
};

export default (state = initialState, action) => {
  // console.log(action);

  switch (action.type) {
    // saves the token into the state
    case AUTH_SET_TOKEN:
      return {
        ...state,
        error: false,
        token: action.token,
      };
    // discards the current token (logout)
    case AUTH_DISCARD_TOKEN:
      return {};
    // saves the current user
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_RESTART_LOGIN_PROCESS:
      return {
        user: {
          id: null,
          returning_user: null,
        },
        isLoggedIn: false,
        token: {},
        status: 1,
        error: false,
      };
    case AUTH_SENDING_OTP:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case AUTH_SENT_OTP:
      return {
        ...state,
        user: action.user,
        isLoggedIn: false,
        loading: false,
        error: false,
        status: 2,
      };
    case AUTH_SENT_OTP_FAILED:
      return {
        ...state,
        loading: false,
        errorSent: true,
      };
    case AUTH_VERIFYING_OTP:
      return {
        ...state,
        error: false,
      };
    case AUTH_VERIFIED_OTP:
      return {
        ...state,
        error: false,
        isLoggedIn: true,
        status: 3,
      };
    case AUTH_FAILED_OTP:
      return {
        ...state,
        error: true,
      };
    case AUTH_PROFILE_UPDATE:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_ADDRESS_UPDATE:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_PLACE_ORDER_UPDATE:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_CART_UPDATE:
      return {
        ...state,
        user: action.user,
      };
    // as always, on default do nothing
    default:
      return state;
  }
};

export function sendOTP(user = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: AUTH_SENDING_OTP,
    });
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url("/customers/send_otp.json")
      .post({
        email: user.email,
        first_name: "",
        phone: user.phone,
      })
      .badRequest((err) => throwErr(dispatch, "Bad request. Please try again later!", err))
      .unauthorized((err) => throwErr(dispatch, "Unauthorization issue. Please try re-logging in.", err))
      .forbidden((err) => throwErr(dispatch, "You don' have permission to do this operation.", err))
      .notFound((err) => throwErr(dispatch, "The resource you've been searching for not found.", err))
      .timeout((err) => throwErr(dispatch, "Timeout! Please try again!", err))
      .internalError((err) => throwErr(dispatch, "Oops! Something went wrong! Please contact support.", err))
      .fetchError((err) =>
        throwErr(
          dispatch,
          "Some network error has been occured. Verify you've proper credentials and network access",
          err
        )
      )
      .json((response) => handleOtpSendResponse(response, dispatch));
  };
}

const handleOtpSendResponse = (response, dispatch) => {
  console.log("handling OTP", response);

  if(response.status === "error") {
    return dispatch({
      type: AUTH_SENT_OTP_FAILED,
      user: response.data,
    });
  }
  return dispatch({
    type: AUTH_SENT_OTP,
    user: response.data,
  });
};

export function verifyOTP(user = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: AUTH_VERIFYING_OTP,
    });
    const userId = get(getState(), ["auth", "user", "id"]);
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url(`/customers/${userId}/verify_otp.json`)
      .post({
        otp: user.otp,
      })
      .badRequest((err) => throwErr(dispatch, "Bad request. Please try again later!", err))
      .unauthorized((err) => throwErr(dispatch, "Unauthorization issue. Please try re-logging in.", err))
      .forbidden((err) => throwErr(dispatch, "You don' have permission to do this operation.", err))
      .notFound((err) => throwErr(dispatch, "The resource you've been searching for not found.", err))
      .timeout((err) => throwErr(dispatch, "Timeout! Please try again!", err))
      .internalError((err) => throwErr(dispatch, "Oops! Something went wrong! Please contact support.", err))
      .fetchError((err) =>
        throwErr(
          dispatch,
          "Some network error has been occured. Verify you've proper credentials and network access",
          err
        )
      )
      .json((response) => {
        handleOtpVerifyResponse(response, dispatch);
        return pushWholeCartToServer(dispatch, getState());
      })
      .catch((error) => {
        handleOTPErr(error, dispatch);
      });
  };
}

const handleOtpVerifyResponse = (response, dispatch) => {
  dispatch({
    type: AUTH_SET_TOKEN,
    token: response.token,
  });

  // TODO: SET headers
  return dispatch({
    type: AUTH_VERIFIED_OTP,
    response: response,
  });
};

const handleOTPErr = (err, dispatch) => {
  console.log(err);
  return dispatch({
    type: AUTH_FAILED_OTP,
  });
};

export function restartLoginProcess() {
  return (dispatch) => {
    dispatch({
      type: AUTH_RESTART_LOGIN_PROCESS,
    });
    dispatch({
      type: "cart/CLEAR_CART",
    });
    dispatch({
      type: "addr/DISCARD_ADDRESS",
    });
    dispatch(push("/?showLoginModal=true"));
  };
}

export function saveProfileDetails(user = {}) {
  console.log("saving profile details in the object");
  return (dispatch, getState) => {
    const authentication = get(getState(), ["auth", "token"], {});
    const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);
    return rootApiWithHeaders
      .url(`/customers/update_profile.json`)
      .put(user)
      .json((response) => handleProfileUpdateResponse(response, dispatch))
      .catch((error) => {
        handleOTPErr(error, dispatch);
      });
  };
}
const handleProfileUpdateResponse = (response, dispatch) => {
  console.log("handling profile update success", response);
  // dispatch({
  //   type: AUTH_SET_TOKEN,
  //   token: response.token
  // })
  dispatch({
    type: AUTH_PROFILE_UPDATE,
    user: response,
  });
  console.log("pushing now");
  return dispatch(push("/addresses"));
};
