import { calculateDistance } from "../molecules/amount-details";

export const getOrderedCities = (cities, userLocation) => {
  return cities.map(city => {
    const distance = calculateDistance(userLocation.lat, userLocation.lng, city.latitude, city.longitude)
    return {
      ...city,
      distance
    }
  }).sort((a,b) => a.distance - b.distance);
}
