import React, { useState } from "react";
// import get from 'lodash/get';
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Badge from "../../atoms/badge";
import { updateActiveStore } from "../../modules/shop";
import Modal from "../modal";
import { setStorePicker } from "../../modules/cities";

export const ChooseShopFromChain = () => {
  const dispatch = useDispatch();
  const [showShopFilter, setShopFilter] = useState(false);
  const chainDetails = useSelector(state => {
    return state.chain;
  });
  const currentShop = useSelector(state => {
    return get(state, ["shop"]);
  });
  const onClick = () => {
    // Old code to show the popup
    // () => setShopFilter(true);
    dispatch(setStorePicker(true));
  };
  return !isEmpty(chainDetails) && chainDetails.shops.length > 1 ? (
    <div className="ChooseShopFromChain">
      {!showShopFilter ? (
        <div onClick={onClick} className="ChooseShopFromChain__item">
          <span> {currentShop.name}</span>
        </div>
      ) : (
        <ShopSwitchModal onSelect={setShopFilter} chainDetails={chainDetails} />
      )}
    </div>
  ) : null;
};

export const ShopSwitchModal = ({ onSelect }) => {
  const chainDetails = useSelector(state => {
    return state.chain;
  });
  return (
    <Modal onClose={() => onSelect(false)} title="Choose shop">
      <ShopSelector chainDetails={chainDetails} onSelect={() => onSelect(false)} />
    </Modal>
  );
};
const ShopSelector = ({ chainDetails, onSelect }) => {
  const activeShopId = useSelector(state => {
    return get(state, ["shop", "id"]);
  });
  return (
    <div className="ShopSelector">
      <ul>
        {chainDetails.shops.map(shop => {
          return (
            <li key={shop.id}>
              <ShopBox shop={shop} onSelect={onSelect} active={shop.id === activeShopId} />
            </li>
          );
        })}
      </ul>
      <p className="muted" onClick={onSelect}>
        [X] Close shop selector
      </p>
    </div>
  );
};

export const updateStore = (dispatch, shop, active, onSelect) => {
  dispatch(updateActiveStore(shop));
  onSelect && onSelect();
  dispatch(setStorePicker(false));
};
const ShopBox = ({ shop, onSelect, active }) => {
  const dispatch = useDispatch();

  return (
    <div
      className={`shopBox ${active ? "shopBox--active" : ""}`}
      onClick={() => updateStore(dispatch, shop, active, onSelect)}
    >
      <div className="shopBox__info">
        <h4>{shop.name}</h4>
        {shop.customer_care_number ? <p>Contact : {shop.customer_care_number}</p> : null}
        {shop.types && shop.types.length ? <p>{shop.types.map(type => generateIcon(type.name))}</p> : null}
      </div>
    </div>
  );
};
const iconMap = {
  grocery: "plastic",
  vegetable: "veg",
  food: "dish",
  meat: "chicken",
  fish: "fisher"
};

const generateIcon = (name = {}) => {
  if (iconMap[name.toLowerCase()]) {
    return <span className={`shopBox__type-icon icon-${iconMap[name.toLowerCase()]}`} title={name} key={name} />;
  }
  return (
    <Badge type="primary" key={name}>
      {name}
    </Badge>
  );
};
