import { withHeaderedRootApi } from "./api";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { throwErr } from "./error";
import { notification } from 'antd';

export const ADD_TO_CART = "cart/ADD_TO_CART_REQUESTED";
// Remove only one item from cart
export const REDUCE_FROM_CART = "cart/REDUCE_FROM_CART_REQUESTED";
// Delete item
export const REMOVE_FROM_CART = "cart/REMOVE_FROM_CART_REQUESTED";
// Delete whole Cart
export const CLEAR_CART = "cart/CLEAR_CART";
// Get CART synced with server.
export const CART_SYNCED = "cart/CART_SYNCED";
// Push every items in the cart to server.
export const SYNC_WHOLE_CART_WITH_SERVER = "cart/SYNC_WHOLE_CART_WITH_SERVER";

const initialState = {
  items: [],
  cart_syncing: false
};

export default (state = initialState, action) => {
  // Reset cart if somehow cart is cleared
  if (!state.items) {
    state.items = [];
  }
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart_syncing: true,
        items: addToItemsArray(action.item, state.items)
      };
    case CART_SYNCED:
      return getUpdatedCart(state.items, action.cart);
    case REDUCE_FROM_CART:
      return {
        ...state,
        cart_syncing: true,
        items: reduceCountFromItemsArray(action.item, state.items)
      };
    case SYNC_WHOLE_CART_WITH_SERVER:
      return pushWholeCartToServer(state.items);
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.item.id)
      };

    case CLEAR_CART:
      return {
        items: []
      };

    default:
      return state;
  }
};

// Action dispatcher
export const addToCart = item => {
  return (dispatch, getState) => {
    const cartShopId = get(getState(), ["cart", "shop_id"], get(getState(), ["cart", "items", 0, "shop_id"]));
    const showAlert = cartShopId && cartShopId !== item.shop_id;
    if (showAlert) {
      const clearCart = window.confirm("Your cart will be cleared!, Do you want to continue");
      if (clearCart) {
        dispatch({
          type: CLEAR_CART
        });
      } else {
        return;
      }
    }
    dispatch({
      type: ADD_TO_CART,
      item: item
    });
    const authentication = get(getState(), ["auth", "token"], {});
    if (!isEmpty(authentication)) {
      const cart = get(getState(), ["cart"], {});
      const cartID = cart.id;

      const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);
      if (cartID) {
        return rootApiWithHeaders
          .url(`/carts/${cartID}/add_to_cart.json`)
          .post({
            item_id: item.id,
            quantity: 1
          })
          .json(response => handleCartUpdateResponse(response, dispatch));
      }
      return rootApiWithHeaders
        .url(`/carts.json`)
        .post({
          items: [item]
        })
        .badRequest(err => throwErr(dispatch, "Bad request. Please try again later!", err))
        .unauthorized(err => throwErr(dispatch, "Unauthorization issue. Please try re-logging in.", err))
        .forbidden(err => throwErr(dispatch, "You don' have permission to do this operation.", err))
        .notFound(err => throwErr(dispatch, "The resource you've been searching for not found.", err))
        .timeout(err => throwErr(dispatch, "Timeout! Please try again!", err))
        .internalError(err => throwErr(dispatch, "Oops! Something went wrong! Please contact support.", err))
        .fetchError(err =>
          throwErr(
            dispatch,
            "Some network error has been occured. Verify you've proper credentials and network access",
            err
          )
        )
        .json(response => handleCartUpdateResponse(response, dispatch));
    }
  };
};

const handleCartUpdateResponse = (response, dispatch) => {
  if(response.status === 'error') {
    notification.error({
      message: 'Uh oh! Something happened',
      description:
      response.message,
      placement: 'bottomLeft'
    });
  }
  return dispatch({
    type: CART_SYNCED,
    cart: response
  });
};

// This function is a hack. as it'll get direct state from auth.
// TODO: clean this as this file should only contain dispatchers and it's helper functions.
export const pushWholeCartToServer = (dispatch, state) => {
  console.log(state);

  const items = get(state, ["cart", "items"], {});
  const authentication = get(state, ["auth", "token"], {});
  const shopId = get(state, ["shop", "id"], "");
  const rootApiWithHeaders = withHeaderedRootApi(authentication, shopId, dispatch);

  return rootApiWithHeaders
    .url(`/carts.json`)
    .post({
      items: items
    })
    .json(response => handleCartUpdateResponse(response, dispatch));
};

// Action dispatcher
export const reduceFromCart = item => {
  return (dispatch, getState) => {
    dispatch({
      type: REDUCE_FROM_CART,
      item: item
    });
    const authentication = get(getState(), ["auth", "token"], {});
    const cart = get(getState(), ["cart"], {});
    const cartID = cart.id;
    // Already created cart
    if (cartID) {
      const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);
      return rootApiWithHeaders
        .url(`/carts/${cartID}/delete_from_cart.json`)
        .post({
          item_id: item.id,
          quantity: 1
        })
        .json(response => handleCartUpdateResponse(response, dispatch));
    }
  };
};
// Action dispatcher
export const removeFromCart = item => {
  return (dispatch, getState) => {
    dispatch({
      type: REMOVE_FROM_CART,
      item: item
    });
    const authentication = get(getState(), ["auth", "token"], {});
    const cart = get(getState(), ["cart"], {});
    const cartID = cart.id;
    // Already created cart
    if (cartID) {
      const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);
      return rootApiWithHeaders
        .url(`/carts/${cartID}/delete_from_cart.json`)
        .post({
          item_id: item.id,
          quantity: item.quantity
        })
        .json(response => handleCartUpdateResponse(response, dispatch));
    }
  };
};

// Action dispatcher
export const clearWholeCart = () => {
  return dispatch => {
    return dispatch({
      type: CLEAR_CART
    });
  };
};

// Helper function for adding item to cart
function addToItemsArray(product = {}, currentItems = []) {
  let itemAlreadyInCart = currentItems.filter(item => product.id === item.id)[0];
  // OPTIMIZE: this logic
  // Product is already in the cart
  if (itemAlreadyInCart) {
    let updatedCart = currentItems.reduce((newCart, item) => {
      if (item.id === itemAlreadyInCart.id) {
        item.quantity++;
      }
      newCart.push(item);
      return newCart;
    }, []);
    return updatedCart;
  }
  // New product
  product.quantity = 1;
  return [product, ...currentItems];
}

function reduceCountFromItemsArray(product = {}, currentItems = []) {
  let updatedCart = currentItems.reduce((newCart, item) => {
    if (item.id === product.id) {
      item.quantity--;
      if (item.quantity > 0) {
        newCart.push(item);
      }
    } else {
      newCart.push(item);
    }
    return newCart;
  }, []);
  return updatedCart;
}

function getUpdatedCart(items, apiCart) {
  let apiCartItems = apiCart.data;
  let cart = {
    ...apiCartItems,
    cart_syncing: false,
    items: items.map(item => Object.assign({}, item, updatedCartItemFromServer(apiCart.items, item)))
  };
  function updatedCartItemFromServer(apiCartItems = [], item) {
    let updatedCartItem = apiCartItems.filter(apiItem => {
      return apiItem["item_id"] === item.id;
    })[0];
    // Because server item.id is different than our id. Our ID is item id instead of a random number.
    // Server stores item id as `item-id`
    if (updatedCartItem) {
      delete updatedCartItem.id;
    }
    return updatedCartItem;
  }
  return cart;
}
