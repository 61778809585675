import LoginModal from "../../containers/login-modal";
import React, { useEffect, useState } from "react";
import { useQuery } from "../../hooks/useAuthentication";
import IconLink from "../../atoms/iconLink";

export const LoginLink = ({ clazzName = "" }) => {
  let showLoginModal = useQuery("showLoginModal");
  const [showModal, setShowModal] = useState(!!showLoginModal);
  const openLoginModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    setShowModal(!!showLoginModal);
  }, [showLoginModal]);

  return (
    <>
      <IconLink fontIcon="icon-user" onClick={openLoginModal} className={clazzName}>
        Login
      </IconLink>
      {showModal ? <LoginModal onClose={openLoginModal} /> : null}
    </>
  );
};
