import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { updateStore } from "../ChooseShopFromChain";
import { Skeleton, List , Modal } from "antd";
import { ShopItem } from "../ShopItem";
import styles from "./ShopPicker.module.css";
import TitleBar from "../../atoms/titleBar";
import { getThemeValue } from "../../utils/helpers";

import { AntdBtn } from '../AntdButton'

export const ShopPicker = () => {
  const appLink = getThemeValue(["app", "link"])

  const chainDetails = useSelector(state => {
    return state.chain;
  });
  const activeShopId = useSelector(state => {
    return get(state, ["shop", "id"]);
  });
  const activeCityId = useSelector(state => {
    return get(state, ["cities", "activeCity", "id"]);
  });
  const cities = useSelector(state => {
    return state.cities.items;
  });
  const dispatch = useDispatch();
  const selectShop = useCallback(
    (shop, isActiveShop) => {
      if(shop.is_open !== false) {
        updateStore(dispatch, shop, isActiveShop);
      } else {
        Modal.info({
          title: 'This shop is not taking orders now. Call our support when this shop is back.'
        });
      }
    },
    [dispatch]
  );
  const [isLoading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  useEffect(
    () => {
      // Old whicart clients may not have city set for their shops. So if cities are not there, show only shop pickers.
      if (cities.length) {
        let shps = chainDetails.shops ? chainDetails.shops.filter(shop => shop.location_id === activeCityId) : [];
        shps = shps.sort((shp1, shp2) => shp2.is_open === false ? -1 : 1)
        setShops(shps);
        if (shps.length === 1) {
          selectShop(shps[0], activeShopId === shps[0].id);
        }
        setLoading(!activeCityId);
      } else {
        let shps = chainDetails.shops || [];
        shps = shps.sort((shp1, shp2) => shp2.is_open === false ? -1 : 1)
        setShops(shps);
        if (shps.length === 1) {
          selectShop(shps[0], activeShopId === shps[0].id);
        }
        setLoading(false);
      }
    },
    [cities, activeCityId, chainDetails, isLoading, selectShop, activeShopId]
  );
  return (
    <div className={styles.shopSelector}>
      {
        appLink ? <a href={appLink}>
        <AntdBtn type="link" block>
         Download Android App
        </AntdBtn>
        </a> : null
      }
      {isLoading ? (
        <Skeleton
          loading={isLoading}
          active
          avatar={{ size: "large", shape: "square" }}
          paragraph={{ rows: 3, width: ["40%", "50%", "20%"] }}
        />
      ) : (
        <>
          <TitleBar>{shops.length} services delivering to your area</TitleBar>
          <List
            dataSource={shops}
            renderItem={shop => (
              <List.Item onClick={() => selectShop(shop, shop.id === activeShopId)}>
                <ShopItem shop={shop} />
              </List.Item>
            )}
          />
        </>
      )}
    </div>
  );
};
