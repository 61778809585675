import { getShop } from "../modules/shop";
import get from "lodash/get";

export const helper = (name) => name;

export const generateThemeStyles = () => {
  const shopObject = getShop();
  return `<style>
      :root {
        --primaryColor: ${get(shopObject, ["colors", "primary"], "#1B4964")};
        --secondaryColor: ${get(shopObject, ["colors", "primary"], "#1B4964")};
      }
    </style>`;
};

export const getThemeValue = (path, defaultValue) => {
  const shopObject = getShop();
  return get(shopObject, path, defaultValue);
};
