import React, { useEffect , useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCities, setActiveCity, setStorePicker, setCityPicker } from "../../modules/cities";
import { Drawer, List } from "antd";
import find from "lodash/find";
import get from "lodash/get";
import Badge from "../../atoms/badge";
import { getOrderedCities } from "../../utils/getOrderedCities";
import styles from './city-picker.module.css'
import { RenderFetcher } from "../renderFetcher";


export const CityPicker = () => {
  const dispatch = useDispatch();
  const activeCity = useSelector(state => {
    return state.cities.activeCity;
  });

  const showCityPicker = useSelector(state => {
    return state.cities.showCityPicker;
  });
  const activeShopID = useSelector(state => {
    return get(state, ["shop", "id"]);
  });

  useEffect(() => {
      if (!activeCity) {
        dispatch(setCityPicker(true));
      }
    },
    [activeCity, dispatch]
  );

  useEffect(() => {
    if(activeShopID) {
      dispatch(loadCities());
    }
  }, [dispatch, activeShopID]);

  const onClose = React.useCallback(() => {
    dispatch(setCityPicker(false));
  }, [dispatch]);
  const cities = useSelector(state => {
    return state.cities.items;
  });
  useEffect(
    () => {
      if (!cities.length) {
        dispatch(setCityPicker(false));
        dispatch(setActiveCity(null));
        dispatch(setStorePicker(true));
      } else if (!find(cities, (city) => city.id === activeCity?.id)) {
        dispatch(setCityPicker(true));
        dispatch(setStorePicker(true));
        dispatch(setActiveCity(null));
      }
    },
    [cities, activeCity, dispatch]
  );

  return (
    <Drawer title="Choose your area" placement={"bottom"} closable={!!activeCity} onClose={onClose} visible={showCityPicker} maskClosable={false}>
      <CitiesList close={onClose} />
    </Drawer>
  );
};

const CitiesList = ({ close }) => {
  const dispatch = useDispatch();
  const [orderedCities, setCities] = useState([]);
  const [userLocation, setUserLocation] = useState();

  const cities = useSelector(state => {
    return state.cities.items;
  });

  const isUserPromtedForLocation = useSelector(state => {
    return state.cities.isUserPromtedForLocation;
  });

  const activeCity = useSelector(state => {
    return state.cities.activeCity;
  });


  const onSuccessLocationFetch = (loc) => {
    setUserLocation(loc);
  }

  useEffect(() => {
    if(userLocation) {
      const cits = getOrderedCities(cities, userLocation);
      setCities(cits);
    } else {
      setCities(cities);
    }
  }, [userLocation, cities])

  const setLocation = city => {
    if (activeCity?.id === city.id) {
      return;
    }
    dispatch(setStorePicker(true));
    dispatch(setActiveCity(city));
    close();
  };

  return <>
    <List
      loading={!orderedCities.length}
      dataSource={orderedCities}
      renderItem={city => (
        <List.Item onClick={() => setLocation(city)}>
          <div className="d-flex d-flex-jcsb">
            {city.name}
            {activeCity?.id === city.id ? (
              <Badge type="primary" floatRight>
                active
              </Badge>
            ) : null}
          </div>
        </List.Item>
      )}
    />
    <div className={styles.locationFetcher}>
      {
        isUserPromtedForLocation ? <RenderFetcher onSuccess={onSuccessLocationFetch}/> : null
      }
    </div>
  </>
};
