import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import "./style.css";
import Image from "../image";
import LoadingIndicator from "../loading-indicator";
import WCLink from "../link";
import { updateStore } from "../ChooseShopFromChain";

const BannerImagesHorizontal = (props) => {
  const dispatch = useDispatch();
  const bannerImages = useSelector((state) => {
    return [...get(state, ["shop", "action_banners"], []), ...get(state, ["chain", "action_banners"], [])];
  });
  const isChainLoaded = useSelector((state) => {
    return get(state, ["chain", "banners"]);
  });
  const activeShopId = useSelector((state) => {
    return get(state, ["shop", "id"]);
  });
  const switchShop = (id) => {
    console.log(id, activeShopId);
    // TODO: There's a bug from server side here. Shop ID can be something from other chains
    const isActive = activeShopId === id;
    updateStore(dispatch, { id: id }, isActive);
  };
  return bannerImages && bannerImages.length ? (
    <div className="horizontalBanner">
      <div className="horizontalBanner__images">
        {bannerImages.map(({ image, action_category_id, action_shop_id }, index) => {
          return action_category_id ? (
            <WCLink to={`/category/${action_category_id}`} type="normal" className="tile" {...props}>
              <SlideItem index={index} img={image} />
            </WCLink>
          ) : action_shop_id ? (
            <div>
              <SlideItem index={index} img={image} onClick={() => switchShop(action_shop_id)} />
            </div>
          ) : (
            <SlideItem key={index} img={image} />
          );
        })}
      </div>
    </div>
  ) : !isChainLoaded ? (
    <LoadingIndicator />
  ) : null;
};

const SlideItem = ({ img, onClick }) => {
  return (
    <div className="banner-carousel-slide" onClick={onClick}>
      <div className="banner-carousel__image">
        <Image className="banner-carousel-slide__image" src={img} alt="Banner" />
      </div>
    </div>
  );
};

export default BannerImagesHorizontal;
