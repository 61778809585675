import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addToCart, reduceFromCart } from "../../modules/cart";

import "./style.css";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Image from "../image/index";
import { getCategoryPure } from "../../modules/category";
import { useQuery } from "../../hooks/useAuthentication";
import { useHistory } from "react-router-dom";
// import { Whatsapp } from "react-social-sharing";
import { useCurrency } from "../../hooks/useCurrency";
import { NonVegLabel } from "./non-veg-label";
import { Link } from "react-router-dom";

class ProductsGrid extends React.Component {
  render() {
    const items = get(
      this.props,
      "items",
      get(this.props, "category.items", [])
    );
    return (
      <div className="products-grid__container">
        <div className="products-grid">
          {items.length ? (
            items.map((item) => (
              <ProductCard data={item} key={item.id} {...this.props} />
            ))
          ) : (
            <>
              <p style={{ paddingLeft: "15px" }}>No items found!</p>
            </>
          )}
        </div>
      </div>
    );
  }
}

const ProductCard = ({ data, category, ...props }) => {
  const productId = useQuery("productId");
  const showBrowseOther = useQuery("showBrowseOther");
  const showCart = useQuery("showCart");
  const [showFullScreen, setFullScreen] = useState(
    Number(productId) === Number(data.id)
  );
  const history = useHistory();

  console.log(
    `/category/${data.category_id}?productId=${data.id}&shopId=${data.shop_id}`
  );
  const [prodCategory, setProductCategory] = useState(category);
  if (!prodCategory) {
    console.log("no category is found");
    setProductCategory({
      is_active: false,
    });
    // call category details.
    getCategoryPure(data.category_id, data.shop_id).then((response) => {
      setProductCategory(response);
    });
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const cartItem = props.cart.items.filter(
    (cartItem) => cartItem.id === data.id
  )[0];
  const isProductPresentInCart = !isEmpty(cartItem);
  // const location = window.location.origin;
  // const shopName = useSelector((state) => get(state, ["shop", "name"], "online"));
  const currency = useCurrency();
  const card = (
    <div className="product-card" data-producid={data.id}>
      <div className="product-card__firstfold">
        <Link
          to={`/category/${data.category_id}?productId=${data.id}&shopId=${data.shop_id}`}
        >
          <div className="product-card__image-wrapper">
            <Image
              className="product-card__image"
              src={data.image}
              alt={data.name}
            />
          </div>
        </Link>
        {data.is_veg !== null ? <NonVegLabel enabled={data.is_veg} /> : null}
        {isProductPresentInCart ? (
          <div className="product-card__cart_op">
            {props.reduceFromCart ? (
              <button
                className="product-card__cta--danger"
                onClick={() => props.reduceFromCart(data)}
              >
                <span className="icon-minus t-h3" />
              </button>
            ) : null}
            {cartItem.quantity && (
              <p className="product-card__count">{cartItem.quantity}</p>
            )}
            {props.addToCart ? (
              <button className=" " onClick={() => props.addToCart(data)}>
                <span className="icon-plus t-h3" />
              </button>
            ) : null}
          </div>
        ) : data.is_active !== false &&
          prodCategory &&
          prodCategory.is_active !== false &&
          data.stock > 0 ? (
          <button
            className="product-card__cart_op product-card__cart_op_empty"
            onClick={() => props.addToCart(data)}
          >
            <span className="icon-plus t-h3" />
          </button>
        ) : (
          <button
            className="product-card__cta product-card__cta--error"
            disabled
          >
            Sold out
          </button>
        )}
      </div>
      <div className="product-card__content-wrapper">
        <h3 className="product-card__title t-h3">
          <Link
            to={`/category/${data.category_id}?productId=${data.id}&shopId=${data.shop_id}`}
          >
            {data.name}
          </Link>
        </h3>
        <p className="product-card__description t-h5">{data.description}</p>
        <p className="product-card__meta">
          <span className="product-card__price t-h4">
            {data.price === data.mrp ? (
              <>
                <span className="product-card__currency">{currency}</span>
                {data.price}
              </>
            ) : (
              <>
                <span className="price--striked">
                  <span className="product-card__currency">{currency}</span>
                  {data.mrp}{" "}
                </span>
                <span className="product-card__currency">{currency}</span>
                {data.price}
              </>
            )}
          </span>
        </p>
        {/* <div className="product-card__social">
          <Whatsapp
            link={`${location}/category/${data.category_id}?productId=${data.id}&shopId=${data.shop_id}`}
            message={`Hey checkout this product "${data.name}" from ${shopName}`}
            small
          />
        </div> */}
      </div>
    </div>
  );
  return showFullScreen ? (
    <div className="product--fullscreen">
      <div>
        {card}
        {showBrowseOther ? (
          <button
            className="product-card__cta"
            onClick={() => {
              setFullScreen(false);
              // Navigate to home page.
              history.push("/");
            }}
          >
            Browse other products
          </button>
        ) : null}

        {showCart ? (
          <button
            className="product-card__cta"
            onClick={() => {
              setFullScreen(false);
              // Navigate to home page.
              history.push("/cart");
            }}
          >
            Goto Cart
          </button>
        ) : null}

        {!showCart && !showBrowseOther ? (
          <button
            className="product-card__cta"
            onClick={() => {
              setFullScreen(false);
              history.push("/");
            }}
          >
            Back
          </button>
        ) : null}
      </div>
    </div>
  ) : (
    card
  );
};

const mapStateToProps = ({ cart, auth }) => ({
  cart: cart,
  auth: auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addToCart,
      reduceFromCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductsGrid);
