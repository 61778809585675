import isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCityShops } from "../../hooks/useChainType";
import { getLatestProducts } from "../../modules/trending-products";

import BannerImages from "../../molecules/banner-images";

import { SearchForm } from "../../molecules/search-form";
import { ShopCategories } from "../../molecules/ShopCategories";
import { ShopPicker } from "../../molecules/ShopPicker";

const Home = () => {
  const activeShop = useSelector(state => state.shop);
  const activeLocation = useSelector(state => state.cities.activeCity);
  const dispatch = useDispatch();

  const showShopPicker = useSelector(state => state.cities.showShopPicker);
  const shops = useCityShops();
  useEffect(
    () => {
      if (!isEmpty(activeShop)) {
        dispatch(getLatestProducts());
      }
    },
    [dispatch, activeShop, activeLocation]
  );
  return (
    <div>
      {showShopPicker || shops.length <= 1  ? <BannerImages /> : null}
      {!showShopPicker || <SearchForm /> }
      {showShopPicker ? <ShopPicker /> : <ShopCategories />}
    </div>
  );
};

export default Home;
