import React from 'react';
import { getThemeValue } from "../../utils/helpers";
import { Helmet } from "react-helmet";

export const HelmetContent = () => {
  return <Helmet>
  <title>{getThemeValue(["config", "pageTitle"])}</title>
  <meta name="description" content={getThemeValue(["config", "pageDescription"])} />
  <link rel="manifest" href={getThemeValue(["config", "metaDirectory"], "") + "/manifest.json"} />
  <link rel="shortcut icon" href={getThemeValue(["config", "metaDirectory"], "") + "/favicon.ico"} />
  <meta name="theme-color" content={getThemeValue(["colors", "primary"], "#000000")} />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2048-2732.jpg"} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2732-2048.jpg"} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1668-2388.jpg"} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2388-1668.jpg"} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1536-2048.jpg"} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2048-1536.jpg"} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1668-2224.jpg"} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2224-1668.jpg"} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1620-2160.jpg"} media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2160-1620.jpg"} media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1284-2778.jpg"} media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2778-1284.jpg"} media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1170-2532.jpg"} media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2532-1170.jpg"} media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1125-2436.jpg"} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2436-1125.jpg"} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1242-2688.jpg"} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2688-1242.jpg"} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-828-1792.jpg"} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1792-828.jpg"} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1242-2208.jpg"} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-2208-1242.jpg"} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-750-1334.jpg"} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1334-750.jpg"} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-640-1136.jpg"} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
  <link rel="apple-touch-startup-image" href={getThemeValue(["config", "metaDirectory"], "") + "/splash/apple-splash-1136-640.jpg"} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)" />
  <link
    rel="apple-touch-icon"
    sizes="57x57"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-57x57.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="60x60"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-60x60.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="72x72"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-72x72.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="76x76"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-76x76.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="114x114"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-114x114.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="120x120"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-120x120.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="144x144"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-144x144.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="152x152"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-152x152.png"}
  />
  <link
    rel="apple-touch-icon"
    sizes="180x180"
    href={getThemeValue(["config", "metaDirectory"], "") + "/apple-icon-180x180.png"}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="192x192"
    href={getThemeValue(["config", "metaDirectory"], "") + "/android-icon-192x192.png"}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="32x32"
    href={getThemeValue(["config", "metaDirectory"], "") + "/favicon-32x32.png"}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="96x96"
    href={getThemeValue(["config", "metaDirectory"], "") + "/favicon-96x96.png"}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="16x16"
    href={getThemeValue(["config", "metaDirectory"], "") + "/favicon-16x16.png"}
  />
  <meta name="msapplication-TileColor" content={getThemeValue(["colors", "primary"], "#ffffff")} />
  <meta
    name="msapplication-TileImage"
    content={getThemeValue(["config", "metaDirectory"], "") + "/ms-icon-144x144.png"}
  />
</Helmet>
}
