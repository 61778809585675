import React from "react";
import { useSelector } from "react-redux";
import { Skeleton, Tabs } from "antd";
import get from "lodash/get";
import ProductsGrid from "../products-grid";
import styles from "./shopcategories.module.css";
const { TabPane } = Tabs;

// import styles from './ComponentName.module.css';
export const ShopCategories = () => {
  const trendingProducts = useSelector(state => state.trendingProducts);

  return (
    <div className={styles.tab_container}>
      {get(trendingProducts, ["isProductRequesting"], true) ? (
        <div className={styles.skelton}>
          <div>
            <Skeleton.Image />
            <Skeleton loading={true} active paragraph={{ rows: 1, width: ["90%"] }} />
          </div>
          <div>
            <Skeleton.Image />
            <Skeleton loading={true} active paragraph={{ rows: 1, width: ["90%"] }} />
          </div>
        </div>
      ) : (
        <Tabs defaultActiveKey="0">
          {get(trendingProducts, "products", []).map((category, index) => {
            return (
              <TabPane tab={category.name} key={index}>
                <p className="t-text-muted">{category.description}</p>
                <ProductsGrid category={category} />
              </TabPane>
            );
          })}
        </Tabs>
      )}
    </div>
  );
};
