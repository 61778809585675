import React from "react";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import BoxDetails from "../boxed-details";
import "./styles.css";
import Badge from "../../atoms/badge";
import { useDispatch } from "react-redux";
import { removeAddress } from "../../modules/address";
import { getThemeValue } from "../../utils/helpers";

const AddressDetails = ({
  address = {},
  showChangeShippingAddress,
  active,
  ...props
}) => {
  const dispatch = useDispatch();
  const addressConfig = getThemeValue(["config", "address"], null);
  const deleteAddress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(address.id);
    dispatch(removeAddress({ id: address.id }));
  };
  return !isEmpty(address) ? (
    <BoxDetails {...props} active={active}>
      <div className="address-details__wrapper">
        {active ? <Badge floatRight>active address</Badge> : null}
        <div>
          <p>
            <strong>{address.name}</strong>
          </p>
          <p>{address.address_1},</p>
          <p>{address.address_2},</p>
          <p>
            {address.city}, {address.district}
            {!addressConfig?.exclude?.pincode ? `, ${address.pin}` : null}
          </p>
          <p> {address.phone}</p>
          {!active ? (
            <div className="address-details__delete" onClick={deleteAddress}>
              <span className="icon-trash-empty-1" />
            </div>
          ) : null}
        </div>
      </div>
      {showChangeShippingAddress ? (
        <Link to="/addresses" type="normal" className="muted">
          Change shipping address
        </Link>
      ) : null}
    </BoxDetails>
  ) : null;
};

export default AddressDetails;
