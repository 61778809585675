import React from "react";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { useDispatch, useSelector, connect } from "react-redux";

import { changeActiveDeliveryStaff, getDeliveryStaffFromServer } from "../../modules/deliveryStaff";
import Link from "../../molecules/link";
import DeliveryStaff from "../../molecules/deliverystaff-details";

class DeliveryPartner extends React.Component {
  componentDidMount() {
    this.props.getDeliveryStaffFromServer();
  }
  render() {
    return (
      <div className="address-page">
        <h1>Select delivery</h1>
        {this.props.deliveryStaff.data.length ? (
          <DeliveryStaffGrid staffs={this.props.deliveryStaff.data} />
        ) : (
          <p>Loading...</p>
        )}
        {!this.props.deliveryStaff.data && !this.props.loading ? <p>No delivery information found. </p> : null}
        {this.props.deliveryStaff.data ? <Link to="/place-order">Confirm delivery info</Link> : null}
      </div>
    );
  }
}

const DeliveryStaffGrid = ({ staffs = [] }) => {
  const activeIndex = useSelector((state) => get(state.deliveryStaff, ["activeIndex"], 0));
  const dispatch = useDispatch();
  const changeStaff = (index) => {
    changeActiveDeliveryStaff(
      {
        activeIndex: index,
      },
      dispatch
    );
  };
  return (
    <div className="address-grid">
      {Array.isArray(staffs) &&
        staffs.map((staff, index) => (
          <DeliveryStaff staff={staff} key={index} active={index === activeIndex} onClick={() => changeStaff(index)} />
        ))}
    </div>
  );
};

const mapStateToProps = ({ deliveryStaff }) => {
  return {
    deliveryStaff: deliveryStaff,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDeliveryStaffFromServer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPartner);
