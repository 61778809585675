/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.css";
import FormInput from "../../molecules/input";
// import LoginModal from '../login-modal';
import Button from "../../molecules/button";
import { sendOTP, verifyOTP, saveProfileDetails, restartLoginProcess } from "../../modules/auth";
import get from "lodash/get";
import ProfileDetails from "../../molecules/profile-details";
import { Link } from "react-router-dom";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = get(this.props, ["auth", "user"], {});

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitProfileForm = this.submitProfileForm.bind(this);
    this.showProfileForm = this.showProfileForm.bind(this);
    this.logout = this.logout.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  submitProfileForm(e) {
    e.preventDefault();
    this.props.saveProfileDetails(this.state);
  }
  showProfileForm() {
    this.setState({
      showChangeProfileForm: true,
    });
  }
  logout() {
    this.props.restartLoginProcess();
    this.props.history && this.props.history.push("/");
  }
  render() {
    // let status = get(this.props, ['auth', 'status'], 1);
    // console.log(status);
    return (
      <div className="padding">
        {/* {status < 3 ? <LoginModal /> : null} */}
        <ProfileDetails hideProfileDetails />
        <p>
          <a onClick={this.showProfileForm}>
            Change Profile details
          </a>
        </p>
        {this.state.showChangeProfileForm ? (
          <form onSubmit={this.submitProfileForm} className="form">
            <FormInput
              label="Email"
              id="email"
              disabled={true}
              handleInputChange={this.handleInputChange}
              value={this.state.email}
              type="email"
            />
            <FormInput
              label="First Name"
              id="first_name"
              handleInputChange={this.handleInputChange}
              value={this.state.first_name}
            />
            <FormInput
              label="Last Name"
              id="last_name"
              handleInputChange={this.handleInputChange}
              value={this.state.last_name}
            />
            <FormInput
              label="Phone number"
              id="phone"
              handleInputChange={this.handleInputChange}
              value={this.state.phone}
            />
            <Button type="submit" value="Save profile details" />
          </form>
        ) : null}
        <p><Link to="/orders">My orders </Link></p>
        <p><Link to="/addresses">My addresses </Link></p>
        <a className="link link--danger" onClick={this.logout}>
          Logout
        </a>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth: auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendOTP,
      verifyOTP,
      saveProfileDetails,
      restartLoginProcess,
    },
    dispatch
  );

ProfilePage.propTypes = {
  saveProfileDetails: PropTypes.func,
  restartLoginProcess: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
