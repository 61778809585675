import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCityPicker } from "../../modules/cities";
import "./styles.css";

export const ChooseCity = () => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setCityPicker(true));
  };
  const activeCity = useSelector((state) => {
    return state.cities.activeCity;
  });

  return activeCity ? (
    <div className="ChooseShopFromChain ChooseAddress">
      <p onClick={onClick}>
        <span className="icon-pin" /> {activeCity.name}
      </p>
    </div>
  ) : null;
};
