import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearWholeCart } from "../../modules/cart";

import "./styles.css";
import Link from "../../molecules/link";
import Button from "../../molecules/button";
import Radio from "../../molecules/radio";

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentBy: "cod",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitPaymentForm = this.submitPaymentForm.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;

    this.setState({
      paymentBy: target.name,
    });
  }
  submitPaymentForm(e) {
    e.preventDefault();
    this.props.clearWholeCart();
    this.props.history.push("/thankyou");
  }
  render() {
    return (
      <div>
        <h1>Payment</h1>
        <form onSubmit={this.submitPaymentForm} className="form">
          <Radio
            id="cod"
            label="Cash on delivery"
            value="cod"
            checked={this.state.paymentBy === "cod"}
            handleInputChange={this.handleInputChange}
          />
          <Radio
            id="paytm"
            label="Pay by PayTM"
            value="paytm"
            checked={this.state.paymentBy === "paytm"}
            handleInputChange={this.handleInputChange}
          />
          <Button type="submit" value="Place order" />
        </form>
        <Link to="/checkout">Make changes in checkout page</Link> <br />
        <Link to="/">Add more items to the cart</Link>
      </div>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearWholeCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
