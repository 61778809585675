import { withHeaderedRootApi } from "./api";
import get from "lodash/get";
import { push } from "connected-react-router";
import { CLEAR_CART } from "./cart";
import { getThemeValue } from "../utils/helpers";

export const ORDER_PLACE_ORDER = "order/PLACE_ORDER";
export const ORDER_FAILED_API = "order/FAILED_API";

const initialState = {
  error: false,
  error_message: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ORDER_FAILED_API:
      return {
        ...state,
        error: true,
        error_message: JSON.stringify(action.message)
      };
    default:
      return {
        ...state,
        error: false
      };
  }
};

export function placeOrder() {
  return (dispatch, getState) => {
    // loading indicator
    // dispatch({
    //   type: 'LOADING'
    // });
    console.log("placing order, state before ordering", getState());
    const authentication = get(getState(), ["auth", "token"], {});
    const cartId = get(getState(), ["cart", "id"]);
    const deliveryStaffs = get(getState(), ["deliveryStaff"]);
    const deliveryStaffId = get(deliveryStaffs.data[deliveryStaffs.activeIndex], ["id"]);
    const addresses = get(getState(), ["address"]);
    const addressId = get(addresses.data[addresses.activeIndex], ["id"]);
    const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);

    return rootApiWithHeaders
      .url(`/orders/place_order.json`)
      .post({
        cart_id: cartId,
        delivery_staff_id: getThemeValue(["config", "enableDeliveryPicker"]) ? deliveryStaffId : undefined,
        address_id: addressId
      })
      .json(response => handleSuccessResponse(response, dispatch))
      .catch(error => {
        handleAPIErr(error, dispatch);
      });
  };
}

const handleSuccessResponse = (response, dispatch) => {
  console.log(response);
  dispatch({
    type: CLEAR_CART
  });
  const orderId = get(response, ["data", "id"]);
  return dispatch(push(`/order-success?orderId=${orderId}`));
};

const handleAPIErr = (err, dispatch) => {
  console.log(err);
  // TODO: Use clearWholeCart() instead of direct dispatch
  dispatch({
    type: CLEAR_CART
  });
  return dispatch({
    type: ORDER_FAILED_API,
    message: err
  });
};
