import React from "react";
import "./styles.css";

// eslint-disable-next-line no-unused-vars
export default ({ id, label = "Enter value here", handleInputChange, _type = "radio", value = "", checked }) => {
  return (
    <div className="wrapper">
      <label htmlFor={id}>{label}</label>
      <input type="radio" name={id} id={id} onChange={handleInputChange} value={value} checked={checked} />
    </div>
  );
};
