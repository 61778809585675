import React, { useEffect, useState } from "react";
import LoadingIndicator from "../../molecules/loading-indicator";
import { useAuthentication, useShopId, useShop } from "../../hooks/useAuthentication";
import { withHeaderedRootApi } from "../../modules/api";
import { Typography } from 'antd';

import AmountDetails from "../../molecules/amount-details";
import "./styles.css";
import { DeliveryPersonLocation } from "./location";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const { Text } = Typography;

export const OrderDetailPage = ({ orderIdArg = "" }) => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const authentication = useAuthentication();
  const shopID = useShopId();
  const shop = useShop();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  useEffect(() => {
    const getOrders = async () => {
      const rootApiWithHeaders = withHeaderedRootApi(authentication, shopID, dispatch);
      const orderDetails = await rootApiWithHeaders
        .url(`/orders/${orderId || orderIdArg}.json`)
        .get()
        .json((res) => res.data);
      setOrder(orderDetails || {});
      console.log(orderDetails);

      setLoading(false);
    };
    getOrders();
  }, [authentication, dispatch, orderId, orderIdArg, shopID]);
  return (
    <div className="profile-details padding">
      <h3 className="profile-details__heading">Order Details</h3>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="orderDetails">
          <p className="orderDetails__info">
            <span>Order ID : </span> {order.id}
          </p>
          <p className="orderDetails__info">
            <span>Order PIN : </span> <Text type="success"><strong>{order.pin}</strong></Text>
          </p>
          <p className="orderDetails__info">
            <span>Ordered on : </span> {new Date(order.created_at).toDateString()}
          </p>
          <p className="orderDetails__info">
            <span>Order Status : </span> {order.status}
          </p>
          {order.status === "dispatched" && <DeliveryPersonLocation order={order} />}
          <p className="orderDetails__info">
            <span>Price details: </span>
          </p>
          <AmountDetails cart={order.cart} fromServer={true} />
          {order.note && (
            <p className="orderDetails__info">
              <span>Notes: </span> {order.note}
            </p>
          )}
          {shop.customer_care_number ? (
            <p className="orderDetails__info">
              Contact the shop at <a href={`tel:${shop.customer_care_number}`}>{shop.customer_care_number}</a>
            </p>
          ) : null}
          {order.delivery_staff?.phone ? (
            <p className="orderDetails__info">
              Contact the delivery person {order.delivery_staff?.first_name  ? `${order.delivery_staff?.first_name} ${order.delivery_staff?.last_name}` : '' } at <a href={`tel:${order.delivery_staff?.phone}`}>{order.delivery_staff?.phone}</a>
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};
