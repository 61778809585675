import React, { useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import CartIcon from "../cart-icon";
import logo from "./shopping-cart.svg";
import "./style.css";
import styles from "./header.module.css";
import IconLink from "../../atoms/iconLink";
import { getThemeValue } from "../../utils/helpers";
import { loadChainDetails } from "../../modules/shop";
import { ChooseShopAndAddress } from "../ChooseShopAndAddress";
import { useLocation } from "react-router-dom";
import { LoginLink } from "../login-link";
import { setStorePicker } from "../../modules/cities";
import get from "lodash/get";
import { useCityShops } from "../../hooks/useChainType";

const Header = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadChainDetails());
  }, [dispatch]);
  const location = useLocation();
  const state = location.pathname.split("/")[1];
  const showPicker = useCallback(() => {
    const allowedRoutes = ["", "category", "cart", "search"];
    return allowedRoutes.indexOf(state) > -1;
  }, [state]);
  const activeShop = useSelector((state) => state.shop);

  const isInShopPickerState = useSelector((state) => state.cities.showShopPicker);
  const goBackToShopSelect = () => {
    dispatch(setStorePicker(true));
  };
  const currentShop = useSelector((state) => {
    return get(state, ["shop"]);
  });
  const shops = useCityShops();
  return (
    <div className={`${!isEmpty(activeShop) ? styles.header__selectShop : ""}`}>
      <header className={`header full-width-with-padding`}>
        <div className="header__left">
          {!isInShopPickerState && shops.length > 1 ? (
            <>
              <IconLink linkTo="/" fontIcon="icon-arrow-left" onClick={goBackToShopSelect} />
              <h3 className="t-h3">{currentShop.name}</h3>
            </>
          ) : (
            <IconLink
              icon={getThemeValue(["logo"], logo)}
              to="/"
              linkTo="/"
              alt="logo"
              clazzName="header__logo"
              showContentAllTime
              hideChildren={getThemeValue(["config", "hideBrandNameTitle"], false)}
            >
              {getThemeValue(["name"], "Whicart")}
            </IconLink>
          )}
        </div>
        <div className="header__icons header__icons--mobile">{showPicker() && <ChooseShopAndAddress />}</div>
        <div className="header__icons">
          {showPicker() && <ChooseShopAndAddress />}
          {/* <WishlistIcon /> */}
          {props.auth.isLoggedIn ? (
            <IconLink linkTo="/profile" fontIcon="icon-user">
              Profile
            </IconLink>
          ) : (
            <LoginLink />
          )}
          <CartIcon />
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth: auth,
});

export default connect(mapStateToProps, null)(Header);
