import React, { useEffect } from "react";
import ProductsGrid from "../../molecules/products-grid";
import LoadingIndicator from "../../molecules/loading-indicator";
import { SearchForm } from "../../molecules/search-form";
import { useDispatch, useSelector } from "react-redux";
import { getSearchResults } from "../../modules/searchResults";
import { useQuery } from "../../hooks/useAuthentication";
import get from "lodash/get";
import { Empty , Typography } from 'antd';



const { Text } = Typography;


// A custom hook that builds on useLocation to parse
// the query string for you.

const Search = () => {
  const searchState = useSelector((state) => state.searchResults);
  let query = useQuery("q");
  console.log(searchState, query);
  const dispatch = useDispatch();
  useEffect(() => {
    // As search results are not returning the category object,
    // we need to pass category obj ourselves, otherwise if category is sold out,
    // we cannot make item sold out.
    // dispatch(getCategoriesWith10Items());
  }, []);
  const shopId = useSelector((state) => get(state, ["shop", "id"]));
  useEffect(() => {
    console.log("query changed");
    dispatch(getSearchResults(query));
  }, [query, shopId, dispatch]);
  const itemsFromThisShop = searchState.searchResults.filter((item) => item.shop_id === shopId);
  console.log(itemsFromThisShop, searchState.searchResults);
  return (
    <div>
      <SearchForm />
      {searchState.isSearchRequesting ? (
        <LoadingIndicator />
      ) : searchState.searchResults.length ? <div className="padding">
        <ProductsGrid items={itemsFromThisShop} />
        </div> : (
        <Empty description={<Text type="danger">No items in this shop for the query. Try searching after changing the shop.</Text>} />
      )}
    </div>
  );
};

export default Search;
