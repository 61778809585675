import React, {  useEffect, useState } from 'react';
//import styles from './LocationAsseser.module.css';
import { Drawer, Typography, Alert } from "antd";
import { getThemeValue } from '../../utils/helpers';
import { AntdBtn } from '../AntdButton';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderedCities } from '../../utils/getOrderedCities';
import { setUserOutOfCity, setUserPromtedForLocation } from '../../modules/permissions';
import { RenderFetcher } from '../renderFetcher';
const { Paragraph } = Typography;


export const LocationAsseser = () => {
  const shopName = getThemeValue([ "name"]);

  return shopName ?
    <LocationPermission shopName={shopName}/>
  : null;
}


const LocationPermission = ({shopName}) => {
  const [locationStatus, setLocationPermissionStatus] = useState(null);
  const [st, setSt] = useState(null);
  console.log(st);
  const [hidePermissionDrawer, setPermissionDrawerState] = useState(false);
  const cities = useSelector(state => {
    return state.cities.items;
  });
  const dispatch = useDispatch();

  const onSuccessLocationFetch = (loc) => {
    const orderedCities = getOrderedCities(cities, loc);
    const maxDeliveryDistance = getThemeValue(["config", "maximumDeliveryDistance"])
    const ifUserInsideServiceArea = orderedCities.find(city => city.distance <= maxDeliveryDistance)

    console.log(orderedCities, maxDeliveryDistance, ifUserInsideServiceArea);
    if(!ifUserInsideServiceArea && maxDeliveryDistance) {
      dispatch(setUserOutOfCity(true));
    } else {
      dispatch(setUserOutOfCity(false));
    }
    setSt({
      ifUserInsideServiceArea,
      maxDeliveryDistance,
      orderedCities,
      loc
    })
    setPermissionDrawerState(true);
  }

  useEffect(() => {
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    .then(function(PermissionStatus) {
      setLocationPermissionStatus(PermissionStatus.state);
    })
  });

  return !hidePermissionDrawer ?
   <>
    {
      locationStatus === 'granted' ?
      <RenderFetcher onSuccess={onSuccessLocationFetch}/> :
      (locationStatus === 'denied' ?
      <Denied shopName={shopName}/> :
      (locationStatus === 'prompt' ?
      <Prompt shopName={shopName} onSuccessLocationFetch={onSuccessLocationFetch}/> :
      null))
    }
    </> : null
}


const Denied = ({shopName}) => {
  const [visible, setVisible] = useState(true);
  const onClose = () => {
    setVisible(false);
  };

  return <Drawer title={`${shopName} need your location access`} placement={"bottom"} visible={visible} onClose={onClose} zIndex={1010}>
    <Paragraph>
    {shopName} need your location access to deliver the order.
      <Alert message={<span>Without location, your experience would be minimal using our app.</span>} type="error" showIcon />
      Visit <a href='https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DiOS&oco=1'>this link</a> and follow instructions.
    If this doesn't work, please call our customer support.
    </Paragraph>
  </Drawer>

}

const Prompt = ({shopName, onSuccessLocationFetch}) => {
  const dispatch = useDispatch();
  const [renderFetcher, setFetcher] = useState(false);

  const [visible, setVisible] = useState(true);
  const onClose = () => {
    setVisible(false);
  };

  const fetchLocation = () => {
    dispatch(setUserPromtedForLocation(true))
    setFetcher(true);
  }

  return <Drawer title={`Welcome to ${shopName}`} placement={"bottom"} visible={visible} onClose={onClose} zIndex={1010}>
      <Paragraph>Hello there, <br />
        Please give location access to {shopName} to serve you better.
      </Paragraph>
      <Paragraph><Alert message={`${shopName} uses your location to get your address`} type="info" /> </Paragraph>
      {
        renderFetcher ? <RenderFetcher onSuccess={onSuccessLocationFetch} /> : null
      }
      <AntdBtn type="primary" block onClick={fetchLocation}>
        enable location services
      </AntdBtn>
    </Drawer>
}
