export const SHOPS = [
  {
    // Handle if shop id is wrong, location API gives back based on the shop_id param
    shop_id: 136,
    chain_id: 1,
    shop_slug: "localhost",
    colors: {
      primary: "#f77f00",
    },
    name: "Nila",
    logo: "https://i.ibb.co/NmJx6yq/Frame-21.png",
    app: {
      link: "http://onelink.to/grdnj5",
    },
    config: {
      pageTitle:
        "Nila | Order online food from restaurants in  Ottappalam, free delivery",
      pageDescription: "Nila | Online food delivery on Ottappalam",
      hideBrandNameTitle: true,
      termsAndConditionsLink: "http://terms-and-condition.showcart.in/",
      maximumDeliveryDistance: 10,
      // enableDeliveryPicker: true,
      metaDirectory: "/foodundo",
      address: {
        exclude: {
          pincode: false,
        },
        labels: {
          district: "State ",
        },
      },
      currency: "INR ",
    },
  },
  {
    shop_id: 3,
    chain_id: 3,
    shop_slug: "buyon",
    colors: {
      primary: "#FF1F35",
    },
    name: "BuyOn",
    logo:
      "https://images-whitrie.s3.us-east-2.amazonaws.com/whicart-fe-images/BuyOn-Logo.png",
    app: {
      link: "http://onelink.to/aa3zn4",
    },
    config: {
      pageTitle: "BuyOn | The Joy of Getting The Best ",
      pageDescription:
        "BuyOn | Dear Areekode, Why starve when you have us. We deliver food for you.",
      hideBrandNameTitle: true,
      maximumDeliveryDistance: 50,
      metaDirectory: "/buyon",
    },
  },
  {
    shop_id: 21,
    chain_id: 7,
    shop_slug: "dailychoice",
    colors: {
      primary: "#de2e15",
    },
    name: "Daily Choice",
    logo:
      "https://lh3.googleusercontent.com/EN_bHn0WPzqjivAv_DEidJJdRAzaS1HrWqVdattZxnAITVgBdCS7ipiNNjdPEm5V36U7=s360-rw",
    config: {
      pageTitle: "Daily Choice",
      pageDescription: "Daily Choice",
    },
  },
  {
    shop_id: 69,
    chain_id: 8,
    shop_slug: "limtzo",
    colors: {
      primary: "#f77f00",
    },
    name: "Limtzo",
    app: {
      link: "http://onelink.to/grdnj5",
    },
    logo:
      "https://lh3.googleusercontent.com/okKNBxzR-hAHemyLrPTL06YqxvkDrzmAfWRlpcqFdLHLyiqloa0UKfx77aGH5ZPRrptsFS6not5ToutQJ7QiG4TRQMXrGZTViXjZuUtdsU0Gj5TZK5bV4fHtIxAOdvebMeWASH39OtbGDMjZ3PTNw_4gVCRXALg8rmTNpMETMkObTPhlrNiYl0DH6Dy3O6oOKdpznGMadIwIGbGx_yr5MRrRzMN5mAcvWSfZgfXrmjQBZPS3yGkHCiwP4JTxAEQIcEnyQV_u2JZmaU2vjGE37-0iK7CU2ml8WIRNWQSXlCnECVkS35daSZFKXcz-ObiE8riM7OySSGafao5UIayL3ksr4G1_JU5gVShLmWB_TMYbAvC95ChVf1jvmS-5H96XjPAmLATOuV0SmBeIRTxkKx95tHM05oDVGaxySCCkiUFnXvbzgW2Xo8GoiQSq6222WN0Tkf8rPiirmbrhC5B3Wcn4DYLavOGNm11h6wvQFu3d7jwoLkCvNuY1KU0cs8hBuf3z9u22LkDhBHvDyfCqIqrMDxOO-Pw0ObYy1XgNWuR3iMJi6fbREP7RWBvDJx7I0g3u9rI6yv_fVlglNWfwlX36xA4XXXyktbcCLBACv-iQWqAKthFM7OL3Evg37YhbyZtvpoAWy-yRtcSYqmfPClzKjgFv9QVQ4ni16Q_0j4gneJwKGkYfV6OjWtmlHQw41z653A=w3584-h1876-ft",
    config: {
      pageTitle:
        "Limtzo | Order online food from restaurants in  Ottappalam, free delivery",
      pageDescription: "Limtzo | Online food delivery on Ottappalam",
      hideBrandNameTitle: true,
      metaDirectory: "/limtzo",
    },
  },
  {
    shop_id: 2,
    chain_id: 2,
    shop_slug: "showcart",
    colors: {
      primary: "#0cadf4",
    },
    name: "Showcart",
    logo: "https://i.ibb.co/KKksNg5/SW.png",
    app: {
      link: "http://onelink.to/k6e4ua",
    },
    config: {
      pageTitle:
        "Showcart | Order online food, groceries and other in Perambra, Quilandi, Atholi | free delivery",
      pageDescription: "Showcart | Online food, groceries, meat, fish delivery",
      hideBrandNameTitle: true,
      maximumDeliveryDistance: 10,
      termsAndConditionsLink: "http://terms-and-condition.showcart.in/",
      metaDirectory: "/showcart",
    },
  },
  {
    shop_id: 146,
    chain_id: 9,
    shop_slug: "wpoint",
    colors: {
      primary: "#81b214",
    },
    name: "Whole Sale Point",
    logo: "https://i.ibb.co/FqkcgCm/Frame-2-1.png",
    config: {
      pageTitle: "WholeSale Point | All groceries you need",
      pageDescription: "WholeSale Point | Online supermarket serving Ayippuzha",
      hideBrandNameTitle: true,
      metaDirectory: "/wpoint",
      inUat: true,
    },
  },
  {
    shop_id: 153,
    chain_id: 11,
    shop_slug: "hadiqat",
    colors: {
      primary: "#111",
    },
    name: "Hadiqat",
    logo: "https://i.ibb.co/ct6yMsz/Frame-68.png",
    config: {
      pageTitle: "Hadiqat | For your cooking needs!",
      pageDescription: "Hadiqat | For your cooking needs!",
      hideBrandNameTitle: true,
      metaDirectory: "/hadiqat",
      currency: "AED.",
      address: {
        exclude: {
          pincode: true,
        },
        labels: {
          district: "Emirates",
        },
      },
    },
  },
  {
    shop_id: 155,
    chain_id: 10,
    shop_slug: "foodundo",
    colors: {
      primary: "#E94F08",
    },
    app: {
      link: "http://onelink.to/z9acpx",
    },
    name: "Foodundo",
    logo: "https://i.ibb.co/NmJx6yq/Frame-21.png",
    config: {
      pageTitle: "Foodundo | Order online food from restaurants in  Kuttiadi",
      pageDescription:
        "Foodundo | Online food Kuttiadi with offers and free delivery",
      hideBrandNameTitle: true,
      maximumDeliveryDistance: 10,
      metaDirectory: "/foodundo",
      currency: "₹",
    },
  },
  {
    shop_id: 164,
    chain_id: 13,
    shop_slug: "nilafish",
    colors: {
      primary: "#E94F08",
    },
    name: "nilafish",
    logo: "https://i.ibb.co/HNLkvyw/Nila-Fish.png",
    config: {
      pageTitle: "Nilafish",
      pageDescription: "Nilafish",
      hideBrandNameTitle: true,
      metaDirectory: "/nilafish",
      currency: "₹",
    },
  },
  {
    shop_id: 181,
    chain_id: 14,
    shop_slug: "happybeeday",
    colors: {
      primary: "#f77f00",
    },
    name: "Happy Beeday",
    logo:
      "https://lh3.googleusercontent.com/okKNBxzR-hAHemyLrPTL06YqxvkDrzmAfWRlpcqFdLHLyiqloa0UKfx77aGH5ZPRrptsFS6not5ToutQJ7QiG4TRQMXrGZTViXjZuUtdsU0Gj5TZK5bV4fHtIxAOdvebMeWASH39OtbGDMjZ3PTNw_4gVCRXALg8rmTNpMETMkObTPhlrNiYl0DH6Dy3O6oOKdpznGMadIwIGbGx_yr5MRrRzMN5mAcvWSfZgfXrmjQBZPS3yGkHCiwP4JTxAEQIcEnyQV_u2JZmaU2vjGE37-0iK7CU2ml8WIRNWQSXlCnECVkS35daSZFKXcz-ObiE8riM7OySSGafao5UIayL3ksr4G1_JU5gVShLmWB_TMYbAvC95ChVf1jvmS-5H96XjPAmLATOuV0SmBeIRTxkKx95tHM05oDVGaxySCCkiUFnXvbzgW2Xo8GoiQSq6222WN0Tkf8rPiirmbrhC5B3Wcn4DYLavOGNm11h6wvQFu3d7jwoLkCvNuY1KU0cs8hBuf3z9u22LkDhBHvDyfCqIqrMDxOO-Pw0ObYy1XgNWuR3iMJi6fbREP7RWBvDJx7I0g3u9rI6yv_fVlglNWfwlX36xA4XXXyktbcCLBACv-iQWqAKthFM7OL3Evg37YhbyZtvpoAWy-yRtcSYqmfPClzKjgFv9QVQ4ni16Q_0j4gneJwKGkYfV6OjWtmlHQw41z653A=w3584-h1876-ft",
    config: {
      pageTitle: "Happy Beeday | Order online",
      pageDescription: "Happy Beeday | Online delivery",
      hideBrandNameTitle: true,
      metaDirectory: "/Happy Beeday",
    },
  },
  {
    shop_id: 181,
    chain_id: 15,
    shop_slug: "eater",
    colors: {
      primary: "#f77f00",
    },
    name: "Eater",
    logo:
      "https://lh3.googleusercontent.com/okKNBxzR-hAHemyLrPTL06YqxvkDrzmAfWRlpcqFdLHLyiqloa0UKfx77aGH5ZPRrptsFS6not5ToutQJ7QiG4TRQMXrGZTViXjZuUtdsU0Gj5TZK5bV4fHtIxAOdvebMeWASH39OtbGDMjZ3PTNw_4gVCRXALg8rmTNpMETMkObTPhlrNiYl0DH6Dy3O6oOKdpznGMadIwIGbGx_yr5MRrRzMN5mAcvWSfZgfXrmjQBZPS3yGkHCiwP4JTxAEQIcEnyQV_u2JZmaU2vjGE37-0iK7CU2ml8WIRNWQSXlCnECVkS35daSZFKXcz-ObiE8riM7OySSGafao5UIayL3ksr4G1_JU5gVShLmWB_TMYbAvC95ChVf1jvmS-5H96XjPAmLATOuV0SmBeIRTxkKx95tHM05oDVGaxySCCkiUFnXvbzgW2Xo8GoiQSq6222WN0Tkf8rPiirmbrhC5B3Wcn4DYLavOGNm11h6wvQFu3d7jwoLkCvNuY1KU0cs8hBuf3z9u22LkDhBHvDyfCqIqrMDxOO-Pw0ObYy1XgNWuR3iMJi6fbREP7RWBvDJx7I0g3u9rI6yv_fVlglNWfwlX36xA4XXXyktbcCLBACv-iQWqAKthFM7OL3Evg37YhbyZtvpoAWy-yRtcSYqmfPClzKjgFv9QVQ4ni16Q_0j4gneJwKGkYfV6OjWtmlHQw41z653A=w3584-h1876-ft",
    config: {
      pageTitle: "Eater | Order online",
      pageDescription: "Eater | Online delivery",
      hideBrandNameTitle: true,
      metaDirectory: "/Eater",
    },
  },
];

// Limtzo: https://coolors.co/000d14-d62828-f77f00-fffffc-faf9ef
