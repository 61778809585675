import React from "react";
import { push } from "connected-react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./style.css";

const Footer = () => (
  <footer className="footer full-width-with-padding">
    {/* <p className="footer__main">Whicart Products, Calicut, 673007 | 10:00 AM - 11:00 PM | TEL: +91 9012345678</p>
    <ul className="footer__links">
      <li>FAQ</li>
      <li>CONTACT US</li>
      <li>MY ACCOUNT</li>
    </ul> */}
    {/* <div className="footer__social">
      Follow us on:
      <ul>
        <li>Facebook</li>
        <li>Twiiter</li>
        <li>Instagram</li>
      </ul>
    </div> */}
    <div className="footer__copyright"> Whitrie Solutions Pvt. Ltd © 2019-2020</div>
    <p className="muted--small text-center">
      Icons made by
      <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
        Freepik
      </a>{" "}
      from{" "}
      <a href="https://www.flaticon.com/" title="Flaticon">
        Flaticon
      </a>
    </p>
    <p className="muted--small text-center">Whicart v0.7</p>
  </footer>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: () => push("/about-us")
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(Footer);
