import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { Badge } from 'antd';

import "./style.css";
import IconLink from "../../atoms/iconLink";

const CartIcon = ({ cart, clazzName }) => {
  const count  = get(cart, ["items"], []).length > 0 ? get(cart, ["items"], []).length : 0;
  return <IconLink to="/cart" alt="cart" fontIcon="icon-basket-1" showContentAllTime clazzName={clazzName}>
      <Badge count={count} ></Badge>
    </IconLink>

};

const mapStateToProps = ({ cart }) => ({
  cart: cart,
});

export default connect(mapStateToProps, null)(CartIcon);
