import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.css";
import FormInput from "../../molecules/input";
import { sendOTP, verifyOTP, saveProfileDetails, restartLoginProcess } from "../../modules/auth";
import get from "lodash/get";
import Modal from "../../molecules/modal";
import { AntdBtn } from "../../molecules/AntdButton";
import { Typography, Space } from 'antd';
import { getThemeValue } from '../../utils/helpers';

const { Text } = Typography;

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.account.showLoginModal,
      email: "",
      phone: ""
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitLoginForm = this.submitLoginForm.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.submitProfileForm = this.submitProfileForm.bind(this);
    this.retry = this.retry.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  submitLoginForm(e) {
    e.preventDefault();
    this.props.history.push("/payment");
  }
  sendOTP(e) {
    e.preventDefault();
    this.props.sendOTP(this.state);
  }
  verifyOTP(e) {
    // Get user details and update forms
    e.preventDefault();
    this.props.verifyOTP(this.state);
  }
  submitProfileForm(e) {
    e.preventDefault();
    this.props.saveProfileDetails(this.state);
  }
  retry(e) {
    e.preventDefault();
    this.props.restartLoginProcess(this.state);
  }
  render() {
    let status = get(this.props, ["auth", "status"], 1);
    return (
      <div>
        {status === 1 ? (
          <Modal onClose={this.props.onClose} title="Login">
            <LoginForm
              {...this.state}
              {...this.props}
              handleInputChange={this.handleInputChange}
              sendOTP={this.sendOTP}
            />
          </Modal>
        ) : status === 2 ? (
          <Modal onClose={this.props.onClose} title="Verify">
            <VerifyOtpForm
              {...this.state}
              {...this.props}
              handleInputChange={this.handleInputChange}
              verifyOTP={this.verifyOTP}
              retry={this.retry}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function LoginForm({ sendOTP, handleInputChange, _email, phone, auth }) {

  return (
    <form onSubmit={sendOTP} className={`form`}>
      {/* <FormInput label="Email" id="email" handleInputChange={handleInputChange} value={email} type="email" /> */}
      <FormInput label="Phone number" id="phone" handleInputChange={handleInputChange} value={phone} type="tel" />
      <p>{get(auth, ["errorSent"]) ? <Text type="danger">Couldn't send OTP. Please try again.</Text> : null}</p>
      <AntdBtn block  type="primary" htmlType="submit" loading={get(auth, ["loading"])}>Send OTP</AntdBtn>
    </form>
  );
}

function VerifyOtpForm({ verifyOTP, handleInputChange, otp, auth, retry }) {
  const showTerms = getThemeValue(["config", "termsAndConditionsLink"]);
  console.log('showTerms', showTerms);
  return (
    <form onSubmit={verifyOTP} className={`form`}>
      <FormInput label="OTP" id="otp" handleInputChange={handleInputChange} value={otp} />
      <p>{get(auth, ["error"]) ? <Text type="danger">Please insert correct OTP</Text> : <Text type="success">We've sent you an OTP through SMS. Please fill the OTP above. </Text>}</p>
      {showTerms ?
        <p>
          <Text type="secondary">By verifying you agree to our <a href={showTerms} target="_blank" rel="noopener noreferrer" >terms and conditions</a></Text>
        </p>
      : null}
      <Space>
      <AntdBtn  type="primary" htmlType="submit" >Verify OTP</AntdBtn>
      <AntdBtn  type="link" onClick={retry}>Go back</AntdBtn>
      </Space>
    </form>
  );
}
const mapStateToProps = ({ auth = {}, account = {} }) => ({
  auth: auth,
  account: account
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendOTP,
      verifyOTP,
      saveProfileDetails,
      restartLoginProcess
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModal);
