import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import Home from "../home";

import CategoryPage from "../category";
import About from "../about";

import Cart from "../cart";
import Wishlist from "../wishlist";

import ProfilePage from "../profile-page";
import Addresses from "../addresses";
import Payment from "../payment";
import Thankyou from "../thankyou";
import Header from "../../molecules/header";
import Footer from "../../molecules/footer";
import { OrderSuccess, OrdersPage } from "../orders";
import PlaceOrder from "../place-order";
import "./styles.css";

// import Bottombar from '../../molecules/bottombar';

// import Carousel from '../../molecules/carousel';
import Signupform from "../../molecules/sign-up-form";
import Search from "../search";
import { Navbar } from "../../molecules/navbar";
import ErrorMessages from "../../molecules/error-messages";
import DeliveryPartner from "../deliverypartner";
import { OrderDetailPage } from "../orderDetail";
import { getThemeValue } from "../../utils/helpers";
import ComingSoon from "../../atoms/comingSoon";
import { isOnWhicartDomain, updateActiveStore } from "../../modules/shop";
import { useQuery } from "../../hooks/useAuthentication";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import "antd/dist/antd.css";
import { CityPicker } from "../../molecules/CityPicker";
import isEmpty from "lodash/isEmpty";
import { setStorePicker } from "../../modules/cities";
import { HelmetContent } from "./helmet";
import { LocationAsseser } from '../../molecules/LocationAsseser';
import { NotServingLocation } from "../../molecules/NotServingLocation";

const App = () => {
  const inUat = getThemeValue(["config", "inUat"], false);
  const shopId = useQuery("shopId");
  const dispatch = useDispatch();
  const chainDetails = useSelector((state) => {
    return state.chain;
  });
  useEffect(() => {
    // Update shop if coming through shared link.
    if (shopId) {
      const newShop = get(chainDetails, ["shops"], []).find((shop) => shop.id === parseInt(shopId));
      if (newShop) {
        dispatch(updateActiveStore(newShop));
      }
    }
  }, [shopId, chainDetails, dispatch]);

  const currentShop = useSelector((state) => {
    return get(state, ["shop"]);
  });
  useEffect(() => {
    // If empty home with shop picker
    if (isEmpty(currentShop) && !isEmpty(chainDetails)) {
      // const firstShop = get(chainDetails, ['shops', 0]);
      // dispatch(updateActiveStore(firstShop));
      dispatch(setStorePicker(true));
    }
  }, [currentShop, chainDetails, dispatch]);
  return inUat && !isOnWhicartDomain() ? (
    <ComingSoon />
  ) : (
    <>
      <HelmetContent />
      <Header />
      {/* <Carousel /> */}
      <ErrorMessages />
      <main className="main-content">
        <Route exact path="/" component={Home} />
        <Route exact path="/category/:categoryId" component={CategoryPage} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/wishlist" component={Wishlist} />
        <Route exact path="/place-order" component={PlaceOrder} />
        <Route path="/search" component={Search} />
        <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/orders" component={OrdersPage} />
        <Route exact path="/order/:orderId" component={OrderDetailPage} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/addresses" component={Addresses} />
        <Route exact path="/delivery-partner" component={DeliveryPartner} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/signupform" component={Signupform} />
        <Route exact path="/thankyou" component={Thankyou} />
        <Route exact path="/order-success" component={OrderSuccess} />
        {/* <Route exact component={NotFound} /> */}
      </main>
      <NotServingLocation />
      <LocationAsseser />
      <CityPicker />
      <Navbar />
      <Footer />
    </>
  );
};

export default App;
