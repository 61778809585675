import React from "react";
import isEmpty from "lodash/isEmpty";
import BoxDetails from "../boxed-details";
import { Link } from "react-router-dom";
const DeliveryStaff = ({ staff = {}, enableChangeDeliveryStaff, ...props }) => {
  return !isEmpty(staff) ? (
    <BoxDetails {...props}>
      <div>
        <p>
          {staff.first_name} {staff.last_name}
        </p>
      </div>
      {enableChangeDeliveryStaff ? (
        <Link to="/delivery-partner" type="normal" className="muted">
          Change delivery partner
        </Link>
      ) : null}
    </BoxDetails>
  ) : null;
};

export default DeliveryStaff;
