import React, { useState } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";
import GoogleMapReact from "google-map-react";
import "./location.css";
import delivery from "./delivery.svg";
import get from "lodash/get";
export const DeliveryPersonLocation = ({ order }) => {
  const [latLng, setLatLng] = useState(order.latLng || { lat: 11.0168, lng: 76.9558 });
  const { "access-token": accessToken, uid, client } = useAuthentication();
  const webSocket = new WebSocket(
    `wss://qa.api.whicart.in/cable/?access_token=${accessToken}&client=${client}&uid=${uid}&user_class=Customer`
  );
  console.log("rendering", webSocket);
  webSocket.onmessage = function (event) {
    console.log("geeting a message", event, event.data);
    if (get(event, ["data", "type"]) === "location") {
      setLatLng(get(event, ["data", "latLng"]));
    }
  };
  return (
    <div style={{ height: "500px", width: "100%" }} className="googleMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCq7ZhcF4NldYxRk4Wq-a01a_qPUhleXbY" }}
        defaultCenter={{ lat: 11.0168, lng: 76.9558 }}
        defaultZoom={11}
      >
        <Marker lat={latLng.lat} lng={latLng.lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
};

const Marker = () => {
  // const { color, name, id } = props;
  return (
    <div className="deliveryIcon">
      <img src={delivery} alt="delivery" />
    </div>
  );
};
