import React from "react";

import "./style.css";
import Image from "../image/index";
import Button from "../button";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useCurrency } from "../../hooks/useCurrency";

const ProductsList = ({ products = [], ...props }) => {

  // TODO: Check this effect needed.
  // useEffect(
  //   () => {
  //     if (!isEmpty(activeShop)) {
  //       dispatch(getLatestProducts());
  //     }
  //   },
  //   [dispatch, activeShop, activeLocation]
  // );
  const totalAmount = products.reduce((total, product) => total + product.price * product.quantity, 0);
  const isLoggedIn = useSelector((state) => get(state, ["auth", "isLoggedIn"]));
  const currency = useCurrency();
  return (
    <div className="products-list">
      {products.map((item, index) => (
        <ProductListItem data={item} key={`${index}-${item.id}`} {...props} />
      ))}
      {!isLoggedIn ? (
        <div className="products-list__row products-list__row--main">
          <p>Subtotal</p>
          <p className="products-list__row__total">
            {currency}
            {totalAmount}
            /-
            <br />
            <span>
              Extra charges may apply. <br />
              Login to see the total amount.
            </span>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const ProductListItem = ({ data, ...props }) => {
  const currency = useCurrency();
  return (
    <div className="product-list-item">
      <div className="product-list-item__image-wrapper">
        <Image className="product-list-item__image" src={data.image} alt={data.name} />
      </div>
      <div className="product-list-item__title">
        <h5>{data.name}</h5>
      </div>
      <p className="product-list-item__price">
        {data.price === data.mrp ? (
          `${currency}${data.price}`
        ) : (
          <>
            <span className="price--striked">
              {currency}
              {data.mrp}{" "}
            </span>
            {currency}
            {data.price}
          </>
        )}
      </p>
      {
        data.stock > 0 && data.is_active ?
      <div className="product-list-item__count-wrapper">
        {props.reduceFrom ? (
          <Button onClick={() => props.reduceFrom(data)}>
            <span className="icon-minus" />
          </Button>
        ) : null}
        {data.quantity && <p className="product-list-item__count">{data.quantity}</p>}
        {props.addTo ? (
          <Button btnStyle="success" onClick={() => props.addTo(data)}>
            <span className="icon-plus" />
          </Button>
        ) : null}
      </div> : <div className="product-list-item__count-wrapper product-list-item__count-wrapper--out-of-stock">
        <p className="product-list-item__count">Out of stock</p>
      </div>}
      {props.removeFrom ? (
        <Button onClick={() => props.removeFrom(data)} btnStyle="danger">
          <span className="icon-trash-empty-1" />
        </Button>
      ) : null}
    </div>
  );
};

export default ProductsList;
