import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addToWishlist, removeFromWishlist, reduceFromWishlist, clearWholeWishlist } from "../../modules/wishlist";
import Link from "../../molecules/link";
import Button from "../../molecules/button";
import ProductsList from "../../molecules/products-list";
import "./style.css";

const Wishlist = ({ wishlist, ...props }) => (
  <div>
    <h1>Wishlist</h1>
    <div className="wishlist">
      <ProductsList
        products={wishlist.items}
        reduceFrom={props.reduceFromWishlist}
        addTo={props.addToWishlist}
        removeFrom={props.removeFromWishlist}
        {...props}
      />
    </div>
    {wishlist.items.length ? (
      [
        <Button onClick={() => props.clearWholeWishlist()} key="deleteall" value="Delete all items from wishlist" />,
        <br key="br" />,
        <Link to="/checkout" key="checkout">
          Checkout
        </Link>,
        <p key="or">Or</p>,
      ]
    ) : (
      <p>No items in your wishlist. </p>
    )}
    <Link to="/">Continue shopping</Link>
  </div>
);

const mapStateToProps = ({ wishlist }) => ({
  wishlist: wishlist,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addToWishlist,
      reduceFromWishlist,
      removeFromWishlist,
      clearWholeWishlist,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);
