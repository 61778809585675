import { useHistory } from "react-router-dom";
import React from "react";
import BoxDetails from "../../molecules/boxed-details";
import Badge from "../../atoms/badge";
import { useCurrency } from "../../hooks/useCurrency";

export const Orders = ({ orders }) => {
  console.log(orders);

  return (
    <ul className="orders">
      {orders.map((order) => {
        return (
          <li key={order.id}>
            <OrderInfo order={order} />
          </li>
        );
      })}
    </ul>
  );
};

const getOrderClass = (type) => {
  type = type.toLowerCase();
  switch (type) {
    case "processed":
      return "warning";
    case "dispatched":
      return "warning";
    case "delivered":
      return "success";
    case "canceled":
    case "cancelled":
      return "danger";
    default:
      return "default";
  }
};

const OrderInfo = ({ order = {} }) => {
  const history = useHistory();
  const currency = useCurrency();
  return (
    <BoxDetails onClick={() => history.push(`/order/${order.id}`)}>
      <Badge type={getOrderClass(order.status)} floatRight>
        {order.status}
      </Badge>
      <p>Id : {order.id}</p>
      <p>Ordered on: {new Date(order.created_at).toLocaleDateString()}</p>
      <p>
        Total amount {currency}
        <strong>{order.cart.amount_payable}</strong>
        /- for <strong>{order.cart.cart_items.length}</strong> item(s)
      </p>
      <p className="muted">Click to see more details</p>
    </BoxDetails>
  );
};
