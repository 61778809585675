import { withRootApi } from "./api";
import { throwErr } from "./error";

export const SHOW_SHOP_PICKER = "cities/SHOW_SHOP_PICKER";
export const SHOW_CITY_PICKER = "cities/SHOW_CITY_PICKER";
export const LOAD_CITIES = "cities/LOAD";
export const SET_ACTIVE_CITY = "cities/SET_ACTIVE";

const initialState = {
  showShopPicker: false,
  showCityPicker: false,
  items: [],
  activeCity: null,
};

export const citiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SHOP_PICKER:
      return {
        ...state,
        showShopPicker: action.payload,
      };
    case SHOW_CITY_PICKER:
      return {
        ...state,
        showCityPicker: action.payload,
      };
    case LOAD_CITIES:
      return {
        ...state,
        items: action.payload.filter((city) => city.is_active),
      };
    case SET_ACTIVE_CITY:
      return {
        ...state,
        activeCity: action.payload,
      };
    default:
      return state;
  }
};

export const setStorePicker = (shouldSet) => {
  return {
    type: SHOW_SHOP_PICKER,
    payload: shouldSet,
  };
};

export const setCityPicker = (shouldSet) => {
  return {
    type: SHOW_CITY_PICKER,
    payload: shouldSet,
  };
};

export const loadCities = () => {
  return (dispatch, getState) => {
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url("/locations.json")
      .get()
      .json((response) => {
        return handleResource(response, dispatch);
      });
  };
};

export const setActiveCity = (city) => {
  return {
    type: SET_ACTIVE_CITY,
    payload: city,
  };
};


const handleResource = (response, dispatch) => {
  return dispatch({
    type: LOAD_CITIES,
    payload: response.data,
  });
};

export default citiesReducer;
