import React from "react";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { useDispatch, useSelector, connect } from "react-redux";

import "./styles.css";
import {
  saveAddressToServer,
  getAddressFromServer,
  changeActiveAddress,
} from "../../modules/address";
import Link from "../../molecules/link";
import AddressDetails from "../../molecules/address-details";
import AddressForm from "./address-form";
import { getThemeValue } from "../../utils/helpers";
import LoadingIndicator from "../../molecules/loading-indicator";
import { isEmpty } from "lodash";
import { getURLParamValue } from "../../hooks/useAuthentication";

class Addresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewForm: false,
    };
    this.toggleNewAddressForm = this.toggleNewAddressForm.bind(this);
  }
  componentDidMount() {
    this.props.getAddressFromServer();
  }
  toggleNewAddressForm() {
    this.setState({
      showNewForm: !this.state.showNewForm,
    });
  }
  componentDidUpdate() {
    if (
      !this.props.address.loading &&
      isEmpty(this.props.address.data) &&
      !this.state.showNewForm
    ) {
      this.toggleNewAddressForm();
    }
  }
  render() {
    const nextState =
      getURLParamValue("redirectTo") ||
      (getThemeValue(["config", "enableDeliveryPicker"], false)
        ? "/delivery-partner"
        : "/place-order");
    const buttonLabel =
      getURLParamValue("buttonLabel") || "Confirm shipping address";
    return (
      <div className="address-page">
        <h1>Your address</h1>
        {this.props.address.loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!this.state.showNewForm ? (
              <AddressGrid addresses={this.props.address.data} />
            ) : (
              <AddressForm
                onSubmit={() =>
                  this.setState({ showNewForm: !this.state.showNewForm })
                }
              />
            )}
            <div className="address-page__cta">
              {!this.state.showNewForm && !isEmpty(this.props.address.data) ? (
                <Link to={nextState} type="success">
                  {buttonLabel}
                </Link>
              ) : null}
              <p
                onClick={this.toggleNewAddressForm}
                className="address-page__toggle-state muted"
              >
                {!this.state.showNewForm ? "Or, add a new address" : null}
              </p>
            </div>
            {!isEmpty(this.props.address.data) && this.state.showNewForm ? (
              <p
                onClick={this.toggleNewAddressForm}
                className="address-page__toggle-state"
              >
                Use current active address
              </p>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

const AddressGrid = ({ addresses = [] }) => {
  const activeIndex = useSelector((state) =>
    get(state.address, ["activeIndex"], 0)
  );
  const dispatch = useDispatch();
  const changeAddress = (index) => {
    changeActiveAddress(
      {
        activeIndex: index,
      },
      dispatch
    );
  };
  return (
    <div className="address-grid">
      {Array.isArray(addresses) &&
        addresses.map((address, index) => (
          <AddressDetails
            address={address}
            key={index}
            active={index === activeIndex}
            onClick={() => changeAddress(index)}
          />
        ))}
    </div>
  );
};

const mapStateToProps = ({ address }) => {
  return {
    address: address,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAddressToServer,
      getAddressFromServer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
