import { useSelector } from "react-redux";
import get from "lodash/get";
import { useLocation } from "react-router-dom";

export const useAuthentication = () => {
  return useSelector((state) => {
    return get(state, ["auth", "token"], {});
  });
};

export const useShopId = () => {
  return useSelector((state) => {
    return get(state, ["shop", "id"], {});
  });
};

export const useShop = () => {
  return useSelector((state) => {
    return get(state, ["shop"], {});
  });
};

export const useAddress = () => {
  return useSelector((state) => {
    return get(state, ["address"], {});
  });
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const getURLParamValue = (queryParam) => {
  return new URLSearchParams(window.location.search).get(queryParam);
};
