import React, { useState } from "react";
import FormInput from "../input";
import { useHistory } from "react-router-dom";
import styles from "./SearchForm.module.css";
export const SearchForm = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState();
  const handleInputChange = (val) => {
    setSearchQuery(val);
  };
  const submitAddressForm = (e) => {
    e.preventDefault();
    history.push(`/search?q=${searchQuery}`);
  };
  return (
    <form onSubmit={submitAddressForm} className={styles.container}>
      <FormInput
        className={styles.searchForm}
        label="Search for products"
        id="search"
        required="required"
        handleInputChange={(e) => {
          e.preventDefault();
          handleInputChange(e.target.value);
        }}
        value={searchQuery}
        type="text"
        icon="icon-search-1"
      />
    </form>
  );
};
