import { withHeaderedRootApi } from "./api";
import get from "lodash/get";
import last from "lodash/last";

// Settign up authentication token
export const ADDR_SET_ADDRESS = "addr/SET_ADDRESS";
export const ADDR_SET_ACTIVE_ADDRESS = "addr/SET_ACTIVE_ADDRESS";
export const ADDR_APPEND_ADDRESS = "addr/APPEND_ADDRESS";
export const ADDR_PREPEND_ADDRESS = "addr/PREPEND_ADDRESS";
export const ADDR_LOADING = "addr/LOADING";
export const ADDR_DISCARD_ADDRESS = "addr/DISCARD_ADDRESS";
export const ADDR_FAILED_API = "addr/FAILED_API";
export const ADDR_REMOVE = "addr/REMOVE";

const initialState = {
  data: [{}],
  loading: true,
  activeIndex: 0,
  error: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDR_APPEND_ADDRESS:
      return {
        ...state,
        data: [...state.data, action.address],
        loading: false,
        error: false,
      };
    case ADDR_PREPEND_ADDRESS:
      return {
        ...state,
        data: [action.address, ...state.data],
        loading: false,
        error: false,
      };
    case ADDR_REMOVE:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.id),
        noAddressForUser: false,
        loading: false,
        error: false,
      };
    case ADDR_SET_ADDRESS:
      return {
        ...state,
        data: action.address,
        noAddressForUser: action.address.length === 0,
        activeIndex:
          state.activeIndex > action.address.length - 1
            ? action.address.length - 1
            : state.activeIndex,
        loading: false,
        error: false,
      };
    case ADDR_SET_ACTIVE_ADDRESS:
      return {
        ...state,
        loading: false,
        noAddressForUser: false,
        activeIndex: action.activeIndex,
      };
    case ADDR_DISCARD_ADDRESS:
      return {
        data: [{}],
        loading: false,
        noAddressForUser: true,
        error: false,
      };
    case ADDR_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADDR_FAILED_API:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export function saveAddressToServer(address = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: ADDR_LOADING,
    });
    const authentication = get(getState(), ["auth", "token"], {});
    const rootApiWithHeaders = withHeaderedRootApi(
      authentication,
      getState,
      dispatch
    );
    const currentAddressLength = get(getState(), ["address", "data"], [])
      .length;
    address = {
      ...address,
      pin: address.pin || "0",
    };
    return rootApiWithHeaders
      .url(`/addresses.json`)
      .post(address)
      .json((response) =>
        handleSuccessResponse(response, dispatch, currentAddressLength)
      )
      .catch((error) => {
        handleAPIErr(error, dispatch);
      });
  };
}

export function getAddressFromServer() {
  return (dispatch, getState) => {
    // loading indicator
    // dispatch({
    //   type: 'LOADING'
    // });
    const authentication = get(getState(), ["auth", "token"], {});
    const rootApiWithHeaders = withHeaderedRootApi(
      authentication,
      getState,
      dispatch
    );

    return rootApiWithHeaders
      .url(`/addresses.json`)
      .get()
      .json((response) => {
        console.log("got address, setting", last(response.data));

        dispatch({
          type: ADDR_SET_ADDRESS,
          address: response.data,
        });
      })
      .catch((error) => {
        handleAPIErr(error, dispatch);
      });
  };
}

export const changeActiveAddress = (action, dispatch) => {
  dispatch({
    type: ADDR_SET_ACTIVE_ADDRESS,
    activeIndex: action.activeIndex,
  });
};

export const removeAddress = (action) => {
  return (dispatch, getState) => {
    dispatch({
      type: ADDR_LOADING,
    });
    const authentication = get(getState(), ["auth", "token"], {});
    const rootApiWithHeaders = withHeaderedRootApi(
      authentication,
      getState,
      dispatch
    );
    return rootApiWithHeaders
      .url(`/addresses/${action.id}.json`)
      .delete()
      .json(() => handleAddRemoved(dispatch, action.id))
      .catch((error) => {
        // TODO: This shouldn;t be under catch.
        handleAddRemoved(dispatch, action.id);
        handleAPIErr(error, dispatch);
      });
  };
};

const handleAddRemoved = (dispatch, id) => {
  dispatch({
    type: ADDR_REMOVE,
    id: id,
  });
};

const handleSuccessResponse = (response, dispatch) => {
  // dispatch({
  //   type: ADDR_APPEND_ADDRESS,
  //   address: response.data
  // });
  // dispatch({
  //   type: ADDR_SET_ACTIVE_ADDRESS,
  //   activeIndex: currentAddressLength
  // });
  dispatch({
    type: ADDR_PREPEND_ADDRESS,
    address: response.data,
  });
  dispatch({
    type: ADDR_SET_ACTIVE_ADDRESS,
    activeIndex: 0,
  });

  // return dispatch(push('/addresses'));
};

const handleAPIErr = (err, dispatch) => {
  console.log(err);
  return dispatch({
    type: ADDR_FAILED_API,
  });
};
