import React, { Suspense } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "./styles.css";
import FormInput from "../../molecules/input";
import Button from "../../molecules/button";
import { saveAddressToServer } from "../../modules/address";
import { getThemeValue } from "../../utils/helpers";
const GoogleLocationPicker = React.lazy(() => import("./locationPicker"));

class AddressesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitAddressForm = this.submitAddressForm.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  submitAddressForm(e) {
    e.preventDefault();
    this.props.saveAddressToServer(this.state);
    this.props.onSubmit();
  }
  handleLocationChange(lat, lng) {
    console.log(lat, lng);
    this.setState({
      lat: lat,
      lng: lng,
    });
  }
  render() {
    const addressConfig = getThemeValue(["config", "address"], null);
    return (
      <form onSubmit={this.submitAddressForm} className={`form`}>
        <FormInput
          label="Name"
          id="name"
          required="required"
          handleInputChange={this.handleInputChange}
          value={this.state.name}
          type="name"
        />
        <FormInput
          label="Address Line 1"
          id="address_1"
          required="required"
          handleInputChange={this.handleInputChange}
          value={this.state.address_1}
        />
        <FormInput
          label="Address Line 2"
          id="address_2"
          required="required"
          handleInputChange={this.handleInputChange}
          value={this.state.address_2}
        />
        <FormInput
          required="required"
          label="City"
          id="city"
          handleInputChange={this.handleInputChange}
          value={this.state.city}
        />
        <FormInput
          required="required"
          label={addressConfig?.labels?.district || "District"}
          id="district"
          handleInputChange={this.handleInputChange}
          value={this.state.district}
        />
        {!addressConfig?.exclude?.pincode ? (
          <FormInput
            required="required"
            minLength={4}
            label="Pincode"
            id="pin"
            handleInputChange={this.handleInputChange}
            value={this.state.pin}
          />
        ) : null}

        <FormInput
          required="required"
          minLength={10}
          label="Phone number"
          id="phone"
          handleInputChange={this.handleInputChange}
          value={this.state.phone}
        />
        <Suspense fallback={<div>Loading Location picker</div>}>
          <GoogleLocationPicker
            handleLocationChange={this.handleLocationChange}
          />
        </Suspense>
        <Button type="submit" value="Save Your address" />
      </form>
    );
  }
}

const mapStateToProps = ({ address }) => {
  return {
    address: address,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveAddressToServer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressesForm);
