import React from "react";
import "./styles.css";

const Button = ({
  type = "button",
  value = "ok",
  onClick,
  children,
  btnStyle = "",
  className,
  ...props
}) => (
  <button
    type={type}
    className={`link ${className} link--${btnStyle}`}
    onClick={onClick}
    {...props}
  >
    {children || value}
  </button>
);
export default Button;
export { Button };
