export const SET_USER_OUT_CITY = "permission/SET_USER_OUT_CITY";
export const SET_USER_PROMPT_LOCATION = "permission/SET_USER_PROMPT_LOCATION";

const initialState = {
  isUserOutOfCity: false,
  isUserPromtedForLocation: false,
};

export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_OUT_CITY:
      return {
        ...state,
        isUserOutOfCity: action.payload,
        // When we call this, we sure that, user promted for location access
        isUserPromtedForLocation: true
      }
    case SET_USER_PROMPT_LOCATION:
      return {
        isUserPromtedForLocation: action.payload
      }
    default:
      return state;
  }
};


export const setUserOutOfCity = (isOutOfCity) => {
  return {
    type: SET_USER_OUT_CITY,
    payload: isOutOfCity,
  };
};

export const setUserPromtedForLocation = (asked) => {
  return {
    type: SET_USER_PROMPT_LOCATION,
    payload: asked,
  };
};


export default permissionReducer;
