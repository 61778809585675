import {  useSelector } from "react-redux";
import get from "lodash/get";

export const useCityShops = () => {
  let shops = [];
  const chainDetails = useSelector((state) => {
    return state.chain;
  });
  const cities = useSelector((state) => {
    return state.cities.items;
  });
  const activeCityId = useSelector((state) => {
    return get(state, ["cities", "activeCity", "id"]);
  });
  // Old whicart clients may not have city set for their shops. So if cities are not there, show only shop pickers.
  if (cities.length) {
    shops = (chainDetails.shops && chainDetails.shops.filter((shop) => shop.location_id === activeCityId)) || [];
  } else {
    shops = chainDetails.shops || [];
  }

  return shops;
}
