import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <div>
    <h1>About Us</h1>
    <p>WhiCart by Whitrie Solution Ltd</p>
    <Link to="/">Go to home</Link>
  </div>
);
