import React, { useMemo } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import { get } from "lodash";
import LoadingIndicator, { Loader } from "../loading-indicator";
import { useCurrency } from "../../hooks/useCurrency";
import { Alert } from 'antd';

const calculateDeliveryCharge = (total, shop, chain, address) => {
  let deliveryThreshold = get(shop, ["delivery_threshold"])|| get(chain, ["delivery_threshold"]) || false;
  let deliveryPerKmCharge = get(shop, ["delivery_per_km_charge"])|| get(chain, ["delivery_per_km_charge"])|| 0;
  // check base km fallback
  let deliveryBaseKm = get(shop, ["delivery_base_km"]) || get(chain, ["delivery_base_km"])  || 0;

  deliveryThreshold = Number(deliveryThreshold)
  deliveryPerKmCharge = Number(deliveryPerKmCharge)
  deliveryBaseKm = Number(deliveryBaseKm)


  if (deliveryThreshold && deliveryThreshold <= total) {
    return 0;
  }
  const deliveryBaseCharge = get(shop, ["delivery_charge"]) || get(chain, ["delivery_charge"]) || 0;
  console.log(deliveryPerKmCharge, deliveryBaseKm, shop.lat, shop.long, address.lat, address.lng);
  if (deliveryPerKmCharge && deliveryBaseKm && shop.lat && shop.long && address.lat && address.lng) {
    const distance = calculateDistance(shop.lat, shop.long, address.lat, address.lng);
    const chargableDist = distance - deliveryBaseKm;
    const delivery =
      chargableDist < 0
        ? deliveryBaseCharge
        : parseInt(deliveryBaseCharge) + parseInt(chargableDist * deliveryPerKmCharge);
    console.log(
      "distance between shop and user",
      distance,
      chargableDist,
      deliveryBaseCharge,
      deliveryPerKmCharge,
      "charge",
      delivery
    );
    return delivery;
  }
  return deliveryBaseCharge;
};

export function calculateDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat1 - lat2); // deg2rad below
  var dLon = deg2rad(lon1 - lon2);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(lat1)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return Math.ceil(d) || 0;
}

function deg2rad(deg) {
  return (deg / 180) * 3.141592;
}

export const AmountDetails = ({ cart = {}, fromServer = false }) => {
  console.log(cart);
  const shop = useSelector((state) => get(state, ["shop"], {}));
  const chain = useSelector((state) => get(state, ["chain"], {}));
  const activeIndex = useSelector((state) => get(state.address, ["activeIndex"], 0));
  const activeAddress = useSelector((state) => get(state.address, ["data", activeIndex]));
  const memoizedDeliveryCharge = useMemo(
    () => activeAddress && calculateDeliveryCharge(cart.amount_payable, shop, chain, activeAddress),
    [shop, chain, activeAddress, cart]
  );
  const deliveryCharges = !fromServer
    ? activeAddress
      ? memoizedDeliveryCharge
      : "Add address"
    : cart.delivery_charges;

  const totalAmountPayable = !fromServer
    ? parseFloat(cart.amount_payable) + parseFloat(memoizedDeliveryCharge)
    : cart.amount_payable;
  const currency = useCurrency();
  console.log(memoizedDeliveryCharge, cart.amount_payable, totalAmountPayable);
  if(isNaN(totalAmountPayable)) {
    return <Alert message='Some items are out of stock. Please clear all the cart and try again.' type="error" showIcon />
  }
  return isNaN(memoizedDeliveryCharge) ? (
    <LoadingIndicator />
  ) : (
    <div>
      <div className="amount-details">
        <div className="amount-details__info">
          <p>Cart Total: </p>
          <p>Delivery charges: </p>
          <p>Cart Offer amount:</p>
          <p>Cart Discount:</p>
          <p>
            <strong> Total</strong>
          </p>
        </div>
        <div className="amount-details__data">
          {cart.cart_syncing ? <Loader /> : null}
          <p>
            {cart.total}
            /-
          </p>
          <p>
            {deliveryCharges}
            /-
          </p>
          <p>
            {cart.offer_amount}
            /-
          </p>
          <p className="product-card__price">
            - {cart.discount}
            /-
          </p>
          <p>
            <strong>
              {currency}
              {parseFloat(totalAmountPayable).toFixed(2)}
              /-
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AmountDetails;
