import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import category from "./category";
import searchResults from "./searchResults";
import trendingProducts from "./trending-products";
import cart from "./cart";
import shop, { chainReducer } from "./shop";
import error from "./error";
import address from "./address";
import deliveryStaff from "./deliveryStaff";
import order from "./order";
import wishlist from "./wishlist";
import auth from "./auth";
import cities from "./cities";
import permissions from './permissions';
import createHistory from "history/createBrowserHistory";

export const history = createHistory();

export default combineReducers({
  router: connectRouter(history),
  trendingProducts,
  category,
  cart,
  address,
  deliveryStaff,
  order,
  wishlist,
  auth,
  shop,
  cities,
  permissions,
  searchResults,
  chain: chainReducer,
  error,
});
