import { withHeaderedRootApi } from "./api";
import get from "lodash/get";

// Settign up authentication token
export const ADDR_SET_DELIVERY_STAFF = "addr/SET_DELIVERY_STAFF";
export const ADDR_SET_ACTIVE_DELIVERY_STAFF = "addr/SET_ACTIVE_DELIVERY_STAFF";
export const ADDR_FAILED_API = "addr/FAILED_API";

const initialState = {
  data: [],
  activeIndex: 0,
  error: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDR_SET_DELIVERY_STAFF:
      return {
        ...state,
        data: action.deliveryStaff,
        error: false
      };
    case ADDR_SET_ACTIVE_DELIVERY_STAFF:
      return {
        ...state,
        activeIndex: action.activeIndex
      };
    case ADDR_FAILED_API:
      return {
        ...state,
        error: true
      };
    default:
      return {
        ...state
      };
  }
};

export function getDeliveryStaffFromServer() {
  return (dispatch, getState) => {
    // loading indicator
    // dispatch({
    //   type: 'LOADING'
    // });
    const authentication = get(getState(), ["auth", "token"], {});
    const rootApiWithHeaders = withHeaderedRootApi(authentication, getState, dispatch);

    return rootApiWithHeaders
      .url(`/delivery_details.json`)
      .get()
      .json(response => {
        console.log("got delivery staff details, setting", response.data);

        dispatch({
          type: ADDR_SET_DELIVERY_STAFF,
          deliveryStaff: response.data
        });
      })
      .catch(error => {
        handleAPIErr(error, dispatch);
      });
  };
}

export const changeActiveDeliveryStaff = (action, dispatch) => {
  dispatch({
    type: ADDR_SET_ACTIVE_DELIVERY_STAFF,
    activeIndex: action.activeIndex
  });
};

const handleAPIErr = (err, dispatch) => {
  console.log(err);
  return dispatch({
    type: ADDR_FAILED_API
  });
};
