import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./containers/app";
import LogRocket from 'logrocket';
import "./index.css";
import { generateThemeStyles } from "./utils/helpers";

LogRocket.init('xiv24k/whicart');
const target = document.querySelector("#root");
const themeContainer = document.querySelector("#theme");
themeContainer.innerHTML = generateThemeStyles();
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);
registerServiceWorker();
