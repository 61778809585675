import React, { useEffect, useState } from 'react';
import { useMyLocation } from "../../hooks/useMyLocation";
import {  Typography, Alert } from "antd";
const { Paragraph } = Typography;


export const RenderFetcher = ({onSuccess = () => null}) => {
  const [locationMsg, setLocationMsg] = useState("Your location is being fetching, please wait");
  const [currentPosition, locationStatus] = useMyLocation();

  useEffect(() => {
    switch(locationStatus){
      case -2:
        setLocationMsg('Geolocation is not supported by your browser. Connect with customercare for correct delivery charge & help');
        break;
      case -1:
        setLocationMsg('Unable to retrieve your correct location. Your delivery charge maybe different based on your correct location.');
        break;
      case 1:
        setLocationMsg('Finding your location... This may take upto one minute based on the signal strength.');
        break;
      case 2:
        onSuccess(currentPosition);
        break;
      default:
        setLocationMsg('Unable to retrieve your correct location. Your delivery charge maybe different based on your correct location.');
        break;
    }
  }, [locationStatus, onSuccess, currentPosition]);
  return <Paragraph>
    {locationStatus !== 2 && locationStatus > 0 ? <Alert message={locationMsg} type="info" showIcon /> : null}
    {locationStatus !== 2 && locationStatus < 0 ? <Alert message={locationMsg} type="error" showIcon /> : null}
  </Paragraph>
}
